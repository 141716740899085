import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

import { HomeComponent } from './home/home.component';
import { PageTwoComponent } from './page-two/page-two.component';

export const appRoutes: Route[] = [
    {
      path: '',
      pathMatch: 'full',
      component: HomeComponent,
    },
    {
      path: 'page-two',
      component: PageTwoComponent,
      canActivate: [MsalGuard],
    },
    {
      path: '**',
      component: PageNotFoundComponent,
    },
  ];
