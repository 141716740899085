import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import type { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from '@env';

import { getAuthProviders, provideEnv } from '@evc/platform';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideEnv(environment),
    ...getAuthProviders(environment),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
  ],
};
