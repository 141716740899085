import type { OnInit, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';

import type { UserProfile } from '@evc/platform';
import { MOCK_ORGANIZATIONS, MOCK_USER_PROFILE, OrganizationsService, UserService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { FrameComponent, NavButtonComponent, RightPanelComponent, SvgIconComponent } from '@evc/web-components';
import type { TableRow, TableRowArray } from '@shared/components/table/table.component';
import { TableComponent } from '@shared/components/table/table.component';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import { UserCardComponent } from '@app/components/user-card/user-card.component';
import { UserDetailsComponent } from '@app/components/user-details/user-details.component';

@Component({
  standalone: true,
  selector: 'adm-users',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FrameComponent,
    TranslatePipe,
    NavButtonComponent,
    RightPanelComponent,
    SvgIconComponent,
    UserCardComponent,
    UserDetailsComponent,
    TableComponent,
  ],
})
export class PageUsersComponent implements OnInit {
  #userService = inject(UserService);
  readonly #orgService = inject(OrganizationsService);

  isRightPanelOpen = signal(false);
  user = computed(() => MOCK_USER_PROFILE);
  organizations = computed(() => MOCK_ORGANIZATIONS);
  currentOrganization = computed(() => this.#orgService.current());
  currentOrgUsers: WritableSignal<Maybe<UserProfile[]>> = signal(undefined);

  appTableColumns = signal<string[]>(['Name', 'Role']);
  columnMapping = signal<{ [key: string]: string }>({
    Name: 'name',
    Role: 'role',
  });
  data = signal<TableRowArray>(/* TODO : this.currentOrgUsers() ??*/ [{ name: { value: 'Loading...' }, role: { value: 'Loading...' } }]);

  // TODO : logic for this
  isUserInOrganization = signal(true);
  isUserInvited = signal(true);

  ngOnInit(): void {
    console.log(this.currentOrgUsers());
    this.#getCurrentOrgUsers();
  }

  public sortingDataAccessor(item: TableRow, property: string): string {
    if (property === 'Name') {
      return item.name.value;
    }

    if (property === 'Role') {
      return item.role.value;
    }

    return item[property].value;
  }

  toggleRightPanel(): void {
    this.isRightPanelOpen.set(!this.isRightPanelOpen());
  }

  showUserActions(): void {
    console.log('User Actions');
  }

  resendInvitation(): void {
    console.log('Resend Invitation');
  }

  #getCurrentOrgUsers(): void {
    this.#orgService.getUserList().then((users) => {
      console.log(users);
      // let computedUsers: UserProfile[] = [];
      // users.forEach((user) => {
      //   const computedUser = this.#userService.computeOrgUsers(user);
      //   computedUsers.push(computedUser);
      // });
      // this.currentOrgUsers.set(computedUsers);
    }).catch(async (errors:any = []) => {
      console.error(errors);
    });
  }
}
