import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, EventEmitter, inject, Input, Output, signal, ViewChild } from '@angular/core';
import { FormBuilder, type FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

import { OrganizationsService } from '@evc/platform';
import {
  InfoBannerComponent,
  InputComponent,
  ModalComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import type { DataToFormModel } from '@shared/reactive-form/types/reactive-form.type';
import { EvcValidatorsService } from '@shared/reactive-form/validators/validator.service';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';

export type FormDataInviteUsers = {
  email: string;
  role: string;
};
export type FormModelInvitation = DataToFormModel<FormDataInviteUsers>

@Component({
  selector: 'adm-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  imports: [
    TranslatePipe,
    ModalComponent,
    NavButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    InfoBannerComponent,
    SvgIconComponent,
],
  standalone: true,
  styleUrls: ['./invitation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class InvitationModalComponent implements OnInit, AfterViewInit {
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;
  @ViewChild('userEmailInput') userEmailInputElement!: InputComponent;
  @Output() readonly invitationModalClosed = new EventEmitter<void>();
  @Input({ required: true }) userName!: string;
  @Input() roleOptions!: { value: string; label: string }[];

  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(FormBuilder);
  #formService = inject(EvcFormService<FormDataInviteUsers>);
  #validatorService = inject(EvcValidatorsService);
  #i18nService = inject(I18nService);
  readonly #organizationsService = inject(OrganizationsService);

  bannerMessage = computed(() => this.#i18nService.t('PAGE_TEAM.invitation.banner', { user: this.userName }));
  form!: FormGroup<FormModelInvitation>;
  showError = signal(false);

  inviteUserModel = signal<FormDataInviteUsers>({
    email: '',
    role: 'user',
  });

  toggleModal(): void {
    this.invitationModalClosed.emit();
  }

  ngOnInit(): void {
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.inviteUserModel());

    this.form = this.#formBuilder.group<FormModelInvitation>({
      email: control('email', [
        this.#validatorService.email(),
      ]),
      role: control('role', []),
    });

    this.#formService.onUpdate(
      (value) => {
        this.inviteUserModel.update((inviteUserModel) => ({ ...inviteUserModel, ...value }));
      },
    );
  }

  ngAfterViewInit(): void {
    this.#formService.bind(this.form, this.formDirective, this.#destroyRef);

    if (this.userEmailInputElement) {
      this.userEmailInputElement.input.nativeElement.focus();
    }
  }

  submitForm(event: Event): void {
    event.preventDefault();
    const valid = this.form.valid;

    if (!valid) {
      this.showError.set(true);

      return;
    }

    this.sendInvite();
  }

  sendInvite(): void {
    // eslint-disable-next-line no-console
    const payload = this.form.getRawValue();
    this.#organizationsService.sendUserInvitation$(payload).subscribe({
      next: () => {
        // eslint-disable-next-line no-console
        console.log('success');
        this.toggleModal();
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      },
    });
  }
}
