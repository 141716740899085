/**
 * Auth Service
 * bridge between the MSAL service and the application
 *
 * Note that you can use the MSAL service directly in your components, but it is recommended to use a service to encapsulate the MSAL service.
 *
 * TODO : everithing is private here
 * - may add final subject to redispatch some events
 * - or directly bridge a User and never exposing this flows
 */
import type { Signal } from '@angular/core';
import { DestroyRef, Inject, inject, Injectable, Optional, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import type {
  AccountInfo, AuthenticationResult, EventMessage,
  PopupRequest, RedirectRequest, SilentRequest, SsoSilentRequest,
} from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType, PromptValue } from '@azure/msal-browser';
import { PlatformConfigService } from 'platform/services/config/config.service';
import type { AuthConfig } from 'platform/services/config/config.type';
import type { Observable } from 'rxjs';
import { catchError, filter } from 'rxjs';

// available actions requests (values in .env)
enum ACTIONS {
  SIGN_UP_SIGN_IN='signUpSignIn',
  RESET_PASSWORD='resetPassword',
  EDIT_PROFILE='editProfile'
}
// 🧐 from demo
type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};
type IdTokenClaims = {organization_id:string} & Record<string, unknown>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _destroyRef = inject(DestroyRef);
  private _config!:AuthConfig;
  public get config():AuthConfig {
    return this._config;
  }
  public get clientId(): string {
    return this.config?.clientId;
  }
  private _connected = signal(false);
  public get connected():Signal<boolean> {
    return this._connected.asReadonly();
  }
  public _accessToken = signal<string|undefined>(undefined);
  public get accessToken():Signal<string|undefined> {
    return this._accessToken.asReadonly();
  }
  private _idTokenClaims = signal<IdTokenClaims|undefined>(undefined);
  public get idTokenClaims():Signal<IdTokenClaims|undefined> {
    return this._idTokenClaims.asReadonly();
  }

  public getMsalService():MsalService {
    return this.authService;
  }
  constructor(
    @Optional() @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Optional() private authService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,
    _config: PlatformConfigService,
  ) {
    this._destroyRef.onDestroy(() => {
      // if cleanup needed - here we simply use takeUntilDestroyed
    });

    // ABORT - may use custom flows
    if (!_config.greenfield) return;

    this._config = _config.get('auth')!;
  }

  /** do not forget to call this on app init */
  public init(): void {
    if (!this._config) return;

    // most important part - because standalone, need to trigger this check
    // only then we can check if connected
    // ! we CANNOT request token before this is complete
    this.authService.handleRedirectObservable()
    .subscribe(() => {
      this._checkIfConnected();
    });

    this.authService.instance.enableAccountStorageEvents();

    // MUST check current account on init
    // may retrieve account - then ask for token
    this._onInProgress();

    // those are when redirected from a flow
    this._onRedirectSuccess();
    this._onRedirectFail();
    this._onRegister();
    this._onUnregister();
  }

  /**
   * do a login with redirect
   * - I keep the popup for exemple but you may remove it
   * ? if popup : check bottom for example
   * ? if add scope here - will auto fetch access token for it
   */
  public async login(userFlowRequest?: Partial<RedirectRequest>/* | PopupRequest*/, extra?: Partial<RedirectRequest>):Promise<void> {
    const { redirects } = this._config;
    // add scopes defined in env config
    // if define score here - MSAL will also aquire an AccessToken
    const payload:RedirectRequest = {
      scopes: [],
      redirectUri: redirects.success,
      ...(this.msalGuardConfig?.authRequest ?? {}),
      ...(userFlowRequest??{}),
      ...extra ?? {},
    };
    this.authService.loginRedirect(payload);
  }

  public async editProfile():Promise<void> {
    const { b2cPolicies, redirects } = this._config;
    const editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities[ACTIONS.EDIT_PROFILE],
      scopes: [],
      redirectUri: redirects.success,
    };

    this.login(editProfileFlowRequest);
  }

  /**
   * do a logout with redirect
   * ? if popup : check bottom for example
   */
  public logout():void {
    this.authService.logoutRedirect();
  }

  /** request our accesstoken
   * do not subscribe so let other flow listen for response (_onRedirectSuccess)
   */
  public requestAccessToken(scopes: string[] = [], extra?:Partial<SilentRequest>):Observable<
    void | AuthenticationResult | (AuthenticationResult & { account: AccountInfo; })
  > {
    const { b2cPolicies, redirects } = this._config;
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      throw new Error('requestAccessToken : No active account found !');
    }

    let request: RedirectRequest = {
      authority: b2cPolicies.authorities[ACTIONS.SIGN_UP_SIGN_IN],
      scopes,
      account,
      redirectUri: redirects.success,
    };

    if (extra) {
      request = {
        ...request,
        ...extra,
      };
    }

    return this.authService.acquireTokenSilent(request)
    .pipe(
      // if silent token acquisition fails, fallback to interactive method (prefer popup here)
      catchError((error) => {
        console.error('[auth-service] requestAccessToken - acquireTokenSilent error', { error }, this.msalGuardConfig.interactionType);
        if (error instanceof InteractionRequiredAuthError) {
          return this.msalGuardConfig.interactionType === InteractionType.Popup
            ? this.authService.acquireTokenPopup(request)
            : this.authService.acquireTokenRedirect(request);
        }

        throw error;
      },
    ));

    // ! DO NOT SUBSCRIBE HERE
    // - let above subscribe with filter (EventType.ACQUIRE_TOKEN_SUCCESS) intercept it in this class
    // - and allow other class to subscribe to fetch it easily
    // // .subscribe( (accessTokenReponse) => {
    // //   if(accessTokenReponse != null) {
    // //     let accessToken = accessTokenReponse.accessToken;
    // //     console.log("We got the token! hahaha: " + accessToken);
    // //   }
    // // })
  }

  /** On inProgress : fetch current login data
   * - after a successfull login, if you reload we may check if still loggued (localStorage)
   * ? - if ok, may request a new access-token to complete ?
   */
  private _onInProgress() {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => {
        this._checkIfConnected();
        this._checkAndSetActiveAccount();

        if (this._connected() && !this.accessToken()) {
          const { clientId } = this._config;
          this.requestAccessToken([clientId]);
        }
      });
  }

  /** On redirect success
   * _onRedirectSuccessFromSignupSignin will match login or aquireToken
   * TODO test other actions
   */
  private _onRedirectSuccess() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        this._idTokenClaims.set(idtoken);

        // also match our request access token
        if (this.isTokenFromThisAction(ACTIONS.SIGN_UP_SIGN_IN, idtoken)) {
          this._onRedirectSuccessFromSignupSignin(idtoken, payload);
        }

        // TODO other actions to be tested
        if (this.isTokenFromThisAction(ACTIONS.EDIT_PROFILE, idtoken)) {
          this._onRedirectSuccessFromEditProfile(idtoken, payload);
        }

        if (this.isTokenFromThisAction(ACTIONS.RESET_PASSWORD, idtoken)) {
          this._onRedirectSuccessFromResetPassword(idtoken, payload);
        }

        return result;
      });
  }

  /** On access fail
   * - either a new login or a simple fetch access token
   * + check if reset password then do login flow
   *
   * TODO test this
   */
  private _onRedirectFail() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_FAILURE
          || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((result: EventMessage) => {
        // https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        const IS_FORGOT_PASSWORD_ERROR = result.error && result.error.message.indexOf('AADB2C90118') > -1;
        if (IS_FORGOT_PASSWORD_ERROR) {
          return this._onRedirectFailFromForgotPassword();
        }

        return result;
      });
  }

  // TODO test this
  private _onRegister() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((_result: EventMessage) => {
        this._checkIfConnected();
      });
  }

  /**
   * TODO test this
   * pretty sure that MSAL takes care of cleanup
   */
  private _onUnregister() {
    // simply redirect root => will retrigger flow that will match unconnected state
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((_result: EventMessage) => {
        window.location.pathname = '/';
      });
  }

  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  private _checkAndSetActiveAccount() {
    const { clientId } = this._config;
    const accounts = this.authService.instance.getAllAccounts();
    let activeAccount = this.authService.instance.getActiveAccount() ?? undefined;

    // find 1st account with same clientId
    if (!activeAccount && accounts.length > 0) {
      const matchAccount = accounts.find((account) => account.idTokenClaims?.aud === clientId) ?? accounts[0];
      activeAccount = matchAccount;
      this.authService.instance.setActiveAccount(activeAccount);
    }

    // NO ACCOUNT FOUND
    if (!activeAccount) {
      // throw new Error('No active account found')
      return;
    }

    return activeAccount;
  }

  private saveAccessToken(accessToken?:string) {
    if (accessToken === this._accessToken()
      || accessToken === ''
    ) return;
    this._accessToken.set(accessToken);
  }

  /**
   * We are connected if found at least 1 account
   * TODO explore account vs activeAccount
   * we may be connected but not have access-token yet
   */
  private _checkIfConnected() {
    const isConnected = this.authService.instance.getAllAccounts().length > 0;
    this._connected.set(isConnected);
  }

  private isTokenFromThisAction(action: ACTIONS, idToken: IdTokenClaimsWithPolicyId) {
    const { b2cPolicies } = this._config;
    const policy = b2cPolicies.names[action];

    return idToken.acr === policy || idToken.tfp === policy;
  }

  /**
   * After a success redirect login (signin/signup/fetch access token)
   * @param idtoken
   * @param payload
   */
  private _onRedirectSuccessFromSignupSignin(idtoken: IdTokenClaimsWithPolicyId, payload: AuthenticationResult):void {
    this.authService.instance.setActiveAccount(payload.account);
    this.saveAccessToken(payload.accessToken);
  }

  /**
   * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
   * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
   * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
   * TODO test this
   */
  private _onRedirectSuccessFromEditProfile(
    idtoken: IdTokenClaimsWithPolicyId,
    _payload: AuthenticationResult,
  ):void {
    const { b2cPolicies } = this._config;
    // retrieve the account from initial sing-in to the app
    const originalSignInAccount = this.authService.instance.getAllAccounts()
      .find((account: AccountInfo) =>
        account.idTokenClaims?.oid === idtoken.oid
        && account.idTokenClaims?.sub === idtoken.sub
        && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
          || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn),
      );

    const signUpSignInFlowRequest: SsoSilentRequest = {
      authority: b2cPolicies.authorities[ACTIONS.SIGN_UP_SIGN_IN],
      account: originalSignInAccount,
    };

    // silently login again with the signUpSignIn policy
    this.authService.ssoSilent(signUpSignInFlowRequest);
  }

  /**
   * Below we are checking if the user is returning from the reset password flow.
   * If so, we will ask the user to reauthenticate with their new password.
   * If you do not want this behavior and prefer your users to stay signed in instead,
   * you can replace the code below with the same pattern used for handling the return from
   * profile edit flow (see above ln. 74-92).
   * TODO test this
   */
  private _onRedirectSuccessFromResetPassword(
    _idtoken: IdTokenClaimsWithPolicyId,
    _payload: AuthenticationResult,
  ):void {
    const { b2cPolicies, scopes } = this._config;
    const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities[ACTIONS.SIGN_UP_SIGN_IN],
      scopes: [...scopes],
      prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
    };

    this.login(signUpSignInFlowRequest);
  }

  /**
   * TODO test this
   */
  private _onRedirectFailFromForgotPassword():void {
    const { b2cPolicies } = this._config;
    const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities[ACTIONS.RESET_PASSWORD],
      scopes: [],
    };

    this.login(resetPasswordFlowRequest);
  }
}
