import type { SharedEnvironment } from '@shared/services/config/config.type';

import { ENVIRONMENT } from '@evc/platform';

type AppEnvironment = SharedEnvironment & {
  app: string
}
const root = window?.location.origin ?? '/';

export const environment: AppEnvironment = {
  app: 'org-admin',
  /* SHARED */
  VERSION: '0.0.1',
  LOCALES: ['fr-CA', 'en-US'],
  LOCALES_STORAGE_KEY: 'evc_user_lang',
  /* PLATFORM */
  ENV: ENVIRONMENT.development,
  auth: {
    clientId: 'e970f966-4888-439b-a8e2-8a524b1bb90c',
    tenant: 'evcpltidpdev',
    policies: {
      signUpSignIn: 'b2c_1a_signup_signin',
      resetPassword: 'b2c_1a_passwordreset',
      editProfile: 'b2c_1a_profileedit',
    },
    redirects: {
      success: root,
      fail: root,
      logout: root,
    },
  },
  api: {
    uri: 'https://app-dev-evcpltglbidp-eun-a7bl.azurewebsites.net/', // @see /swagger/index.html for doc
    endpoints: {
      signupsignin: 'v1/idm/signupsignin',
      user: '/v1/user/me',
      organization: {
        create: 'v1/organization/create',
        list: 'v1/organization/list',
        current: 'v1/organization/current',
      },
    },
  },
};
