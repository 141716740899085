import { NgClass } from '@angular/common';
import type { OnInit, Signal, WritableSignal } from '@angular/core';
import { Component, computed, EventEmitter, HostListener, inject, Input, Output, signal } from '@angular/core';
import type { Observable } from 'rxjs';

import type { BadgeItemType, IconListType, Maybe, Menu, MenuItemType, TooltipVisibility } from '@evc/web-components';
import {
  ActionService,
  BadgeButtonComponent,
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  IconEnum,
  IsScrollableDirective,
  NavButtonComponent,
  OptionalAsyncPipe,
  SvgIconComponent,
  TooltipHoverDirective } from '@evc/web-components';

import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import type { UserOrganization } from '../../core-client/organizations/organizations.type';
import { UserService } from '../../core-client/user/user.service';
import type { UserProfile } from '../../core-client/user/user.type';
import { CoreClientUtilService } from '../../core-client/utils/core-client-utils.service';
import { SearchService } from '../../services/search/search.service';
import { StorageService } from '../../services/storage/storage.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- investigate CLOUD-816
@Component({
  selector: 'evc-leftbar',
  standalone: true,
  imports: [
    NgClass,
    SvgIconComponent,
    IsScrollableDirective,
    TooltipHoverDirective,
    NavButtonComponent,
    DropdownMenuComponent,
    CloseOnClickOutsideDirective,
    BadgeButtonComponent,
    OptionalAsyncPipe,
],
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss'],
})
export class LeftbarComponent implements OnInit {
  @Output() readonly isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() leftbarConfig?: Menu[];
  @Input() isOpen = true;
  @Input() id = 'left-bar';

  MAX_ORGANIZATIONS_BEFORE_SCROLL = 4;

  #actionService: ActionService = inject(ActionService);
  #storageService: StorageService = inject(StorageService);
  #searchService: SearchService = inject(SearchService);
  #organizationService: OrganizationsService = inject(OrganizationsService);
  #userService: UserService = inject(UserService);
  #CoreClientUtilService = inject(CoreClientUtilService);

  showOrganizationsMenu = signal(false);
  showTooltip: WritableSignal<TooltipVisibility> = signal('auto');
  profile: Signal<Maybe<UserProfile>>;

  constructor() {
   this.profile = this.#userService.profile;
  }

  @HostListener('transitionend', ['$event'])
  toggleLeftBarFinished(event: TransitionEvent): void {
    if (event.propertyName === 'width') {
      this.setTooltipVisibility();
    }
  }

  ngOnInit(): void {
    const storedIsOpen: string | null = this.#storageService.getLocalValue(
      'evident.evc-topbar.leftbar-isOpen',
    );
    this.isOpen = storedIsOpen !== null ? storedIsOpen === 'true' : true;
    this.isOpenChange.emit(this.isOpen);
    this.setTooltipVisibility();
  }

  setTooltipVisibility(): void {
    this.showTooltip.set(this.isOpen ? 'auto' : 'visible');
  }

  getItemIcon(item: MenuItemType): IconListType {
    return item.icon || IconEnum.Folder;
  }

  toggleLeftBar(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
    this.#storageService.setLocalValue('evident.evc-topbar.leftbar-isOpen', String(this.isOpen));
  }

  handleItemClick(itemClicked: MenuItemType): void {
    this.#searchService.clearSearch();
    this.#actionService.handleAction(itemClicked);
    this.leftbarConfig = this.leftbarConfig?.map(section => ({
      ...section,
      items: section.items?.map(item => ({
        ...item,
        selected: item.key === itemClicked.key,
      })),
    }));
  }

  isTabDisabled(tabKey: string): boolean {
    const currentTab: MenuItemType | undefined = this.leftbarConfig?.map(section => section.items).flat().find(
      item => item?.key === tabKey,
    );

    return !!currentTab?.isDisabled;
  }

  isItemSelected(item: MenuItemType): boolean {
    return item.selected ?? false;
  }

  getStateIcon(): IconListType {
    return this.isOpen ? IconEnum.AngleLeft : IconEnum.AngleRight;
  }

  getConfigLength(): number {
    return this.leftbarConfig?.length ?? 0;
  }

  handleOrganizationBtnClick(): void {
    this.showOrganizationsMenu.set(!this.showOrganizationsMenu());
  }

  getSelectedItem(item: MenuItemType[]): string | Observable<string> {
    return item.find(i => i.selected)?.text ?? item[0].text;
  }

  getCurrentOrganization(organizations: Signal<BadgeItemType[]>): Signal<(UserOrganization) | undefined> {
    return computed(() => organizations ? organizations().find(i => i.current) as UserOrganization : this.#organizationService?.current());
  }

  getOrganizationsMenuConfig(): Signal<BadgeItemType[]> {
    const badgeItems = this.leftbarConfig?.find(section => section.title === 'UserOrganization')?.badgeItems;

    return badgeItems ? badgeItems as Signal<BadgeItemType[]> : this.#organizationService.entries as Signal<BadgeItemType[]>;
  }

  handleHideOrgMenu(): void {
    this.showOrganizationsMenu.set(false);
  }

  handleOrganizationClicked(clickedOrganization: BadgeItemType): void {
    if (this.leftbarConfig) {
      this.leftbarConfig.forEach(section => {
        if (section.title === 'UserOrganization' && section.badgeItems) {
          section.badgeItems().forEach(organization => {
            organization.current = organization.id === clickedOrganization.id;
            this.handleHideOrgMenu();
          });
        }
      });
      this.#organizationService.setCurrent(clickedOrganization.id, true);
    }
  }

  getRoleList(organization: UserOrganization): string {
    return this.#CoreClientUtilService.getRoleList(organization);
  }
}
