import { NgClass, NgStyle } from '@angular/common';
import type { OnChanges, Signal, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AboutModalComponent } from 'platform/components/about-modal/about-modal.component';
import { AppsMenuComponent } from 'platform/components/apps-menu/apps-menu.component';
import { HeaderComponent } from 'platform/components/header/header.component';
import { LeftbarComponent } from 'platform/components/leftbar/leftbar.component';
import { SearchComponent } from 'platform/components/search/search.component';
import { UserMenuComponent } from 'platform/components/user-menu/user-menu.component';
import { OrganizationsService } from 'platform/core-client/organizations/organizations.service';
import type { OptionalProfile } from 'platform/core-client/user/user.service';
import { UserService } from 'platform/core-client/user/user.service';
import { SearchService } from 'platform/services/search/search.service';
import { TopbarConfigType } from 'platform/types/topbar-config.type';

import type { BadgeItemType } from '@evc/web-components';
import {
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  GradientBorderMaskComponent,
  ModalComponent,
  ModalService,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { DefaultConfigService } from '../../services/default-config/default-config.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- investigate CLOUD-816
@Component({
  selector: 'evc-topbar',
  standalone: true,
  imports: [
    ModalComponent,
    AboutModalComponent,
    DropdownMenuComponent,
    HeaderComponent,
    UserMenuComponent,
    CloseOnClickOutsideDirective,
    AppsMenuComponent,
    SvgIconComponent,
    GradientBorderMaskComponent,
    LeftbarComponent,
    NavButtonComponent,
    SearchComponent,
    NgClass,
    NgStyle,
  ],
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnChanges {
  @Output() public readonly login: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();

  @Input() public topbarConfig?: TopbarConfigType;

  public showSettingsMenu = false;
  public showHelpMenu = false;
  public showAppsMenu = false;
  public showUserMenu = false;
  public isLeftBarOpen = true;
  public showSearch = false;

  public userProfile: Signal<OptionalProfile>;
  public currentOrganization: BadgeItemType | undefined;
  public currentUserImage: string | undefined;
  public basePath: string;

  constructor(
    private readonly _userService: UserService,
    public readonly _organizationsService: OrganizationsService,
    private readonly _modalService: ModalService,
    private readonly _searchService: SearchService,
    private readonly _defaultservice: DefaultConfigService,
  ) {
    this.userProfile = this._userService.profile;
    this.basePath = this._defaultservice.basePath;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.topbarConfig?.currentValue) {
      this.topbarConfig = changes.topbarConfig?.currentValue;

      if (this.topbarConfig?.search) {
        this._searchService.setSearch(this.topbarConfig.search);
      }
    }
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  public toggleMenu(menu: string): void {
    switch (menu) {
      case 'help-btn': {
        this.showHelpMenu = !this.showHelpMenu;
        this.showSettingsMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'setting-btn': {
        this.showSettingsMenu = !this.showSettingsMenu;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'apps-btn': {
        this.showAppsMenu = !this.showAppsMenu;
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'user-btn': {
        this.showUserMenu = !this.showUserMenu;
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        break;
      }

      default: {
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }
    }
  }

  public handleHideMenu(): void {
    this.showSettingsMenu = false;
    this.showHelpMenu = false;
    this.showAppsMenu = false;
    this.showUserMenu = false;
  }

  public handleLogin(): void {
    this.login.emit();
  }

  public handleLogout(): void {
    this.logout.emit();
  }

  public getShownModal(modalName: 'about' | 'trash'): boolean {
    return this._modalService.currentShownModal === modalName;
  }

  public hideModal(): void {
    this._modalService.closeCurrentModal();
  }

  public getCurrentOrganizationName(): string | undefined {
    return this._organizationsService?.current()?.name;
  }
}
