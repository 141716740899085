import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { PlatformConfigService } from '../../../services/config/config.service';
/**
 * Force redirect to logout url after MSAL failure
 *
 * @see : [MSAL lib does not handle apps with every routes protected by MSALGuard](
 *  https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4614#issuecomment-1075668401
 * )
 *
 * This guard is a workaround to handle the case where the user is not authenticated after redirect;
 * and so force a redirect to external public URI.
 * - use config : `auth.redirects.fail | uris.logout | 'https://evidentscientific.com'`
 *
 * ### Many kind of failures:
 *
 * Cancelling any flow = auth fail
 * - wait one tick so auth_service may catch specific failures
 * - eg: cancel reset_password = fail login
 *   + so will reload to retry login - we did not trully fail a login here
 *
 * @see AUTH_FAIL_URI env variable to match route path with this guard
 */
export const AuthFailureGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean> => {
  const platformConfigService = inject(PlatformConfigService);
  setTimeout(() => {
    const redirectUrl = route.data.redirect
      ?? platformConfigService.get('auth')?.redirects.fail
      ?? platformConfigService.get('uris')?.logout
      ?? 'https://evidentscientific.com';

    console.warn(`AuthFailureGuard - redirect to ${redirectUrl}`);
    window.location.href = redirectUrl;
  }, 100);

  return of(true);
};
