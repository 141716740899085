import { NgClass } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import type { Observable } from 'rxjs';

import type { BadgeItemType, IconType, Menu, MenuItemType, TooltipVisibility } from '@evc/web-components';
import {
  ActionService,
  BadgeButtonComponent,
  CloseOnClickOutsideDirective,
  CollapsiblePanelComponent,
  DropdownMenuComponent,
  GradientBorderMaskComponent,
  IconEnum,
  IsScrollableDirective,
  MenuItemComponent,
  NavButtonComponent,
  SvgIconComponent,
  TooltipHoverDirective } from '@evc/web-components';

import { DefaultConfigService } from '../../services/default-config/default-config.service';
import { SearchService } from '../../services/search/search.service';
import { StorageService } from '../../services/storage/storage.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- investigate CLOUD-816
@Component({
  selector: 'evc-leftbar',
  standalone: true,
  imports: [
    NgClass,
    SvgIconComponent,
    IsScrollableDirective,
    TooltipHoverDirective,
    NavButtonComponent,
    CollapsiblePanelComponent,
    MenuItemComponent,
    GradientBorderMaskComponent,
    DropdownMenuComponent,
    CloseOnClickOutsideDirective,
    BadgeButtonComponent,
],
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss'],
})
export class LeftbarComponent implements OnInit {
  @Output() public readonly isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public leftbarConfig?: Menu[];
  @Input() public isOpen = true;
  @Input() public id = 'left-bar';
  public basePath: string;

  public showOrganizationsMenu = false;
  public showTooltip: TooltipVisibility = 'auto';

  constructor(
    private readonly _actionService: ActionService,
    private readonly _storageService: StorageService,
    private readonly _searchService: SearchService,
    private readonly _defaultservice: DefaultConfigService,
  ) {
    this.basePath = this._defaultservice.basePath;
  }

  @HostListener('transitionend', ['$event'])
  public toggleLeftBarFinished(event: TransitionEvent): void {
    if (event.propertyName === 'width') {
      this.setTooltipVisibility();
    }
  }

  public ngOnInit(): void {
    const storedIsOpen: string | null = this._storageService.getLocalValue(
      'evident.evc-topbar.leftbar-isOpen',
    );
    this.isOpen = storedIsOpen !== null ? storedIsOpen === 'true' : true;
    this.isOpenChange.emit(this.isOpen);
    this.setTooltipVisibility();
  }

  public setTooltipVisibility(): void {
    this.showTooltip = this.isOpen ? 'auto' : 'visible';
  }

  public getItemIcon(item: MenuItemType): IconType {
    return item.icon || IconEnum.Folder;
  }

  public toggleLeftBar(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
    this._storageService.setLocalValue('evident.evc-topbar.leftbar-isOpen', String(this.isOpen));
  }

  public handleItemClick(itemClicked: MenuItemType): void {
    this._searchService.clearSearch();
    this._actionService.handleAction(itemClicked);
    this.leftbarConfig = this.leftbarConfig?.map(section => ({
      ...section,
      items: section.items?.map(item => ({
        ...item,
        selected: item.key === itemClicked.key,
      })),
    }));
  }

  public isTabDisabled(tabKey: string): boolean {
    const currentTab: MenuItemType | undefined = this.leftbarConfig?.map(section => section.items).flat().find(
      item => item?.key === tabKey,
    );

    return !!currentTab?.isDisabled;
  }

  public isItemSelected(item: MenuItemType): boolean {
    return item.selected ?? false;
  }

  public getStateIcon(): IconType {
    return this.isOpen ? IconEnum.AngleLeft : IconEnum.AngleRight;
  }

  public getConfigLength(): number {
    return this.leftbarConfig?.length ?? 0;
  }

  public handleOrganizationBtnClick(): void {
    this.showOrganizationsMenu = !this.showOrganizationsMenu;
  }

  public getSelectedItem(item: MenuItemType[]): string | Observable<string> {
    return item.find(i => i.selected)?.text ?? item[0].text;
  }

  public getCurrentOrganization(badgeItems: BadgeItemType[]): string {
    return badgeItems.find(badge => badge.current)?.name ?? badgeItems[0].name;
  }

  public getOrganizationsMenuConfig(): Menu | undefined {
    return this.leftbarConfig?.find(section => section.title === 'Organization');
  }

  public handleHideOrgMenu(): void {
    this.showOrganizationsMenu = false;
  }

  public handleOrganizationClicked(clickedOrganization: BadgeItemType): void {
    if (this.leftbarConfig) {
      this.leftbarConfig.forEach(section => {
        if (section.title === 'Organization' && section.badgeItems) {
          section.badgeItems.forEach(organization => {
            organization.current = organization.name === clickedOrganization.name;
            this.handleHideOrgMenu();
          });
        }
      });
    }
  }
}
