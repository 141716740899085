/**
 * dead simple loader
 *
 * wait for the platform to be ready
 * then remove itself
 * TODO : add animation ^^
 */
import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivationEnd, Router } from '@angular/router';
import { filter, first, map } from 'rxjs';

import { LoadingSpinnerComponent } from '@evc/web-components';

import { PlatformService } from '../../platform.service';
import { LoaderService } from './loader.service';

@Component({
  selector: 'evc-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoadingSpinnerComponent],
})
export class LoaderComponent implements OnInit {
  #platformService = inject(PlatformService);
  #loadingService = inject(LoaderService);

  #destroyRef = inject(DestroyRef);
  #router = inject(Router);

  isLoading = toSignal(this.#loadingService.loading$);
  showSpinner = signal(false);
  #animationDone = signal(false);
  get loaded(): Signal<boolean> {
    return this.#platformService.ready;
  }
  get showLoader(): Signal<boolean> {
    return computed(() => !this.loaded() || !this.#animationDone());
  }

  #triggerFadeOut = effect(() => {
    if (!this.loaded()) return;
    untracked(() => {
      this.#fadeOut();
    });
  });

  ngOnInit(): void {
    this.#destroyRef.onDestroy(() => {
      this.#triggerFadeOut.destroy();
    });

    this.#router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      first(),
      map(() => true),
    ).subscribe((activated) => {
      this.showSpinner.set(activated);
    });
  }
  #fadeOut() {
    setTimeout(() => {
      this.#animationDone.set(true);
    }, 250);
  }
}
