<div class="svg-icon svg-icon--{{ size }}">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    focusable="false"
    class="svg-icon__symbol"
  >
    @if(icon){
      <use attr.xlink:href="{{ basePath }}assets/icons/sprite/sprite.svg#{{ icon }}"></use>
    }
    @if(svgPath){
      <use [attr.xlink:href]="svgPath"></use>
    }
  </svg>
</div>
