<div class="apps-menu">
  <div class="apps-menu__title">
    <span>{{ 'menus.applications.title' | platformTranslate }}</span>
    <evc-button type="link" rightIcon="house-blank">
      <span>{{ 'menus.applications.links.home' | platformTranslate }}</span>
    </evc-button>
  </div>

  <div class="apps-menu__applications">
    @for (app of applications; track app.code) {
      <button
        class="apps-menu__item"
        [evcTooltipHover]="app.name"
        (click)="handleAppClick(app)"
      >
        <div class="apps-menu__app-badge">
          <evc-svg-icon size="logo" [svgPath]="getAppsImgPath(app)"></evc-svg-icon>
        </div>
        <span class="apps-menu__app-name">{{ app.name }}</span>
      </button>
    }
    @if(isUserAdmin()) {
      <button
        class="apps-menu__item"
        [evcTooltipHover]="'admin'"
        (click)="handleAdminClick()"
      >
        <div class="apps-menu__app-badge apps-menu__gray-app">
          <evc-svg-icon size="logo" svgPath="{{ basePath() }}apps/admin.svg#admin"></evc-svg-icon>
        </div>
        <span class="apps-menu__app-name">{{ 'menus.applications.links.admin' | platformTranslate }}</span>
      </button>
    }
  </div>
  <evc-button class="apps-menu__more-apps-btn" type="cta" size="xxl" (click)="handleMarketplaceClick()">
    <span class="apps-menu__btn-text">{{ 'menus.applications.links.marketplace' | platformTranslate }}</span>
  </evc-button>
</div>
