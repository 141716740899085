import { KeyValuePipe } from '@angular/common';
import type {
  OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { FormsModule, NgControl } from '@angular/forms';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { EvcTypeInput } from './input.type';

@Component({
  standalone: true,
  selector: 'evc-input',
  templateUrl: 'input.component.html',
  styleUrls: ['./input.component.scss'],
  imports: [
    FormsModule,
    KeyValuePipe,
    SvgIconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() public type: EvcTypeInput = 'text';
  @Input() public options?: { value: string | null; label: string }[]; // For select
  @Input() public size: 'md' | 'lg' = 'md';
  @Input() public textAreaOptions?: { rows: number; maxLength: number }; // For textarea

  @Input() public label = '';
  @Input() public value? = '';
  @Input() public id?: string;
  @Input() public name?: string;
  @Input() public placeholder?: string;
  @Input() public required = false;
  @Input() public readOnly = false;
  @Input() public errorText = '';
  @Input() public showError = true;
  @Input() public maxLength: number | null = null;
  @ViewChild('input') input!: ElementRef<HTMLInputElement | HTMLTextAreaElement>;
  @ViewChild('inputGroupDiv') inputGroupDiv: ElementRef<HTMLInputElement> | null = null;

  #disabled = false;
  @Input() set disabled(disabled: boolean) {
    if (this.controlDir) {
      // eslint-disable-next-line no-console
      console.warn('use formControl.get(thisField).disable()');
    }
    this.#disabled = disabled;
  }
  get disabled(): boolean {
    return this.controlDir?.disabled ?? this.#disabled;
  }

  public controlDir: NgControl | null = null;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  public isSelectOpen = false;
  protected formElementType: 'input' | 'select' | 'textarea' = 'input';

  constructor(
    @Optional() @Self() controlDir: NgControl,
    public readonly injector: Injector,
    public readonly render: Renderer2,
    public readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    if (controlDir) {
      this.controlDir = controlDir;
      controlDir.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    switch (this.type) {
      case 'select':
      case 'textarea':
        this.formElementType = this.type;
        break;
      default:
        // Assign 'input' as a default case for all other types
        this.formElementType = 'input';
        break;
    }
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.input) {
      this.input.nativeElement.value = value;
    }
    this.changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onInputChanged(value: string): void {
    this.value = value;
    this.onChange(value);
  }

  public onBlur(): void {
    this.onTouched();
  }

  public hasErrors(): boolean | null {
    const isInvalid = this.controlDir && this.controlDir.invalid;

    return (this.showError && isInvalid) ?? false;
  }

  public toggleSelect(state: boolean): void {
    this.isSelectOpen = state;
  }
}
