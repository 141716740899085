/**
 * Front-channel logout implementation
 *
 * @see : https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#front-channel-logout
 *
 * A logout in one app will log the user out of all apps within the tenant:
 * - Each app MUST have its own logout URL => which implements this guard to clean caches
 * - On logout, MSAL will load every other app's logout URLs in an iframe => so all caches will be removed
 *
 * This guard will be used to trigger the logout process
 * - Expected to be called only within ADB iframes (and so do not forward the redirect)
 * - That's why we use the `PageNotFoundComponent` as we do not bother what to display here
 *
 * If you want to handle a redirect after this logout:
 * - Please override this guard to pass an extra `{postLogoutRedirectUri: 'your-redirect-url'}` param to the `logout` method
 * - This URI must be registered as redirect_uri in the AAD app
 * - And the `onRedirectNavigate` option still must return false if in iframe!
 *
 * ! We also expect the route to have `data: { logout: true }` as it will prevent authService from fetching any token
 *
 * ```ts
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    component: PageNotFoundComponent,
    data: { logout: true },
  },
  ```
 */
import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

export const AuthLogoutGuard: CanActivateFn = (
): Observable<boolean> => {
  const guardActivationSubject = new Subject<boolean>();
  const authService = inject(MsalService);
  const msal = authService.instance;

  msal.enableAccountStorageEvents();
  localStorage.setItem('logout', 'init');

  msal.initialize()
    .then(() => {
      // const activeAccount = msal.getActiveAccount();
      // const homeAccount = activeAccount?.homeAccountId && msal.getAccountByHomeId(activeAccount.homeAccountId);
      // const account = homeAccount || activeAccount;
      console.log('ready to logout', msal.getAllAccounts());

      msal.logoutRedirect({
        // account,
        onRedirectNavigate: () => {
          const allowRedirect = !BrowserUtils.isInIframe();
          console.log('logout callback', allowRedirect);

          msal.setActiveAccount(null);
          msal.clearCache();

          // TMP - test that we did retrieve this after a logout
          // if set = mean that this flow succeed
          localStorage.setItem('logout', allowRedirect.toString());

          // TMP to be able to debug manualy
          return allowRedirect;
        },
      })
      .then(() => {
        console.log('logout - done');
        guardActivationSubject.next(true);
      });
    });

  console.log('LOGOUT init');

  return guardActivationSubject;
};
