/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { computed, DestroyRef, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from 'platform/core-client/auth/auth.service';
import type { Organization } from 'platform/core-client/organizations/organizations.type';
import { PlatformConfigService } from 'platform/services/config/config.service';
import { catchError, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private _destroyRef = inject(DestroyRef);
  private _currentOrgId:WritableSignal<string|undefined> = signal(undefined);

  public get currentOrgId():Signal<string|undefined> {
    return this._currentOrgId.asReadonly();
  }

  private _entries:WritableSignal<Organization[]> = signal([]);

  public entries = computed<Organization[]>(() => {
    const current = this._currentOrgId();

    return this._entries().map((entry) => ({
      ...entry,
      current: entry.id === current,
    }));
  });

  public get current():Signal<Organization|undefined> {
    return computed(() => {
      if (!this._currentOrgId()) return undefined;

      return this.entries().filter((entry) => entry.id === this._currentOrgId())?.[0];
    });
  }

  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _config: PlatformConfigService,
  ) {
    if (_config.greenfield) {
      this.init();
    }
  }

  /**
   * simply set current org ID > trigger computed so list always with {current:boolean}
   * @param followUpLogin - true only if need to retrigger auth login (new token) (eg on org click - if greenfield)
   */
  public setCurrent(id:string | undefined, followUpLogin=false):void {
    this._currentOrgId.set(id);
    if (this._config.greenfield && id && followUpLogin) {
      this._auth.login({
        extraQueryParameters: { toid: id },
      });
    }
  }

  public setEntries(organizations:Organization[]):void {
    this._entries.set(organizations);
    const current = organizations.find((org) => org.current)?.id;
    if (current) {
      this.setCurrent(current, false);
    }
  }

  private init() {
    effect(() => {
      const token = this._auth.accessToken();
      if (!token) return;
      untracked(() => {
        const current = this._auth.idTokenClaims()?.organization_id;
        this.setCurrent(current, false);
      });
      this.updateOrganizations();
    });
  }

  public updateOrganizations():void {
    this.fetchOrganizations$()
    .subscribe((data) => {
      this._entries.set(data);
    });
  }

  private fetchOrganizations$() {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.organization.list}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.get<Organization[]>(apiUri)),
      catchError(() => of([])),
    );
  }
}
