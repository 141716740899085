export enum ENVIRONMENT {
  testing = 'testing',
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production'
}
export type Env = `${ENVIRONMENT}`

export type AuthEnv = {
  clientId:string
  tenant:string
  policies: {
    signUpSignIn:string
    resetPassword:string
    editProfile:string
  },
  redirects: {
    success: string,
    fail: string,
    logout: string,
  }
}

export type ApiEnv = {
  uri: string
  endpoints: {
    signupsignin: string
    user: string
    organization: {
      create: string
      list: string
      current: string
    },
  },
}

// keys expeced in .env file
export type PlatformEnv = {
  ENV: Env
  auth?: AuthEnv
  api?: ApiEnv
}
