import { inject, Injectable } from '@angular/core';
import type { AbstractControl } from '@angular/forms';

import { I18nService } from '../../services/i18n/i18n.service';
import type { EvcFormErrorKey } from '../types/errors.type';
import type { EvcOptionalValidationErrors, EvcValidationErrors, EvcValidator } from '../types/validators.type';
import { defaultErrorMessages } from './validators.messages.defaults';

export const zipPatterns = {
  ca: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  us: /^\d{5}(-\d{4})?$/,
} as const;
export type ZipPatternCountryCode = keyof typeof zipPatterns

export const patterns = {
  alphanumeric: /^[0-9a-zA-Z]+(?: [0-9a-zA-Z]+)*$/,
  alphanumericWithDash: /^[-0-9a-zA-Z]+(?: [-0-9a-zA-Z]+)*$/,
  /** extra : 1 space and `+'()` */
  alphanumericExtended: /^[-'()0-9a-zA-Z]+(?: [-'()0-9a-zA-Z]+)*$/,
  // keep as refs - may be used in future !
  // latinExtendedA: /^(?:(?![×Þß÷þø])[-'0-9a-zÀ-ÿĀ-ſ])+$/i,
  // latinExtendedB: /^(?:(?![×Þß÷þø])[-'0-9a-zÀ-ÿĀ-ſƀ-ȳ])+$/i,
};
@Injectable({
  providedIn: 'root',
})
export class EvcValidatorsService {
  private I18nService = inject(I18nService);

  public required(message?:string): EvcValidator {
    const error = this.getErrorMessage('required', message);

    return (control) =>
      control.value ? null : error;
  }

  public length(minLen=0, maxLen?:number, message?:string, errorKey='length'): EvcValidator {
    const error = this.getErrorMessage(errorKey, message);

    return ({ value }) => {
      if (!value) return null;

      const trimedValue = value.replace(/\s/g, '');
      if (minLen && trimedValue.length < minLen) return error;
      if (maxLen && trimedValue.length > maxLen) return error;

      return null;
    };
  }
  public minLength(minLen=0, message?:string): EvcValidator {
    return this.length(minLen, undefined, message, 'minLength');
  }
  public maxLength(maxLen=25, message?:string): EvcValidator {
    return this.length(undefined, maxLen, message, 'maxLength');
  }

  public email(message?:string): EvcValidator {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return this.pattern(regex, 'validEmail', message);
  }

  /** international phone - loose format (digits, +, -, ())
   * TODO: Discuss about validation composition
   * - here 1 error for many reason
   * - vs using [min(6), max(15), phoneFormat()] would provide better errors message for our user
  */
  public phone(message?:string): EvcValidator {
    const regex = /^\+?[\d-()]+(?:\s[\d-()]+)*$/;
    const minLen = 6;
    const maxLen = 15;
    const error = this.getErrorMessage('validPhone', message);

    return ({ value }) => {
      if (!value) return null;

      const trimedValue = value.replace(/\s/g, '');
      if (trimedValue.length > maxLen
        || trimedValue.length < minLen
        || !regex.test(value)) return error;

      return null;
    };
  }

  /** zip validation with country pattern
   * @param countryCode - country code will use different pattern
   * @param supportInternational **true** (default) fallback to international (loose) pattern ; **false** return validCountry error.
   */
  public zipCode(countryCode?: ZipPatternCountryCode, supportInternational=true, message?:string): EvcValidator {
    const error = this.getErrorMessage('validZipCode', message);

    const country = countryCode?.toLowerCase() as ZipPatternCountryCode;
    let regex = zipPatterns[country];

    return (control: AbstractControl): EvcOptionalValidationErrors => {
      if (!control.value) return null;

      if (!regex) {
        if (!supportInternational) return error;

        regex = /^(?!.*\s\s)[0-9a-zA-Z- ]{4,10}$/;
      }

      return regex.test(control.value) ? null : error;
    };
  }

  public pattern(regex: RegExp, _error?:EvcValidationErrors): EvcValidator
  public pattern(regex: RegExp, _errorKey?:string, errorMessage?:string): EvcValidator
  public pattern(regex: RegExp, errorKeyOrError:EvcValidationErrors|string='pattern', errorMessage?:string): EvcValidator {
    const error = (() => {
      if (typeof errorKeyOrError === 'string') {
        return this.getErrorMessage(errorKeyOrError, errorMessage);
      }

      return errorKeyOrError;
    })();

    return (control) => {
      // ignore no value - please use required validator
      if (!control.value) return null;

      return regex.test(control.value) ? null : error;
    };
  }

  public getErrorMessage(key:EvcFormErrorKey|string, msg?:string):EvcValidationErrors {
    const message = (() => {
      if (msg) return msg;
      const translated = this.I18nService.t(`errors.${key}`);
      if (translated !== `errors.${key}`) return translated;

      return defaultErrorMessages[key] ?? defaultErrorMessages.unknown;
    })();

    return { [key]: { message } } as EvcValidationErrors;
  }
}
