import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TestSharedComponent } from '@shared/components/test-shared/test-shared.component';
import { SharedConfigService } from '@shared/services/config/config.service';

import type { AppIdentity } from '@evc/platform';
import { PlatformComponent } from '@evc/platform';
import type { MenuItemType } from '@evc/web-components';
import { BtnActionEnum } from '@evc/web-components';

@Component({
  standalone: true,
  imports: [RouterModule, PlatformComponent, TestSharedComponent],
  selector: 'evc-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public title:string;

  public appIdentification = signal<AppIdentity>({
    appTitle$: 'ORG ADMIN',
    appLogo: '../assets/apps/withe_icon_unis 1.svg#withe_icon_unis 1',
    action: () => this._router.navigateByUrl(''),
  }).asReadonly();

  public topTabs = signal<MenuItemType[]>([
    {
      key: 'page-two',
      text: 'Page 2',
      action: {
        type: BtnActionEnum.CALLBACK,
        callbackOrUrl: () => this._router.navigateByUrl('/page-two'),
      },
    },
  ]).asReadonly();

  constructor(
    private readonly _router: Router,
    private readonly _config: SharedConfigService,
  ) {
    this.title = _config.get('app') as string;
  }
}
