@if(content) {
  <div class="collapsible-panel">
    <button class="collapsible-panel__title" (click)="toggleCollapsible($event)">
      <span>{{ content.text }}</span>
      <evc-svg-icon size="md" [icon]="getIcon()"></evc-svg-icon>
    </button>
  
    @if(isOpen) {
      <div class="collapsible-panel__content">
        <ng-content></ng-content>
      </div>
    }
  </div>
}

