/** append lang in header http request */
import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { LanguageService } from './language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  #languageService = inject(LanguageService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = this.#languageService.lang();

    if (!lang) return next.handle(request);

    const headerWithLang = request.headers.append('accept-language', lang);
    const interceptedRequest = request.clone({
      headers: headerWithLang,
    });

    return next.handle(interceptedRequest);
  }
}
