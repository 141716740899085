<div class="admin">
  <evc-platform
    class="admin__platform"
    [inputAppIdentification]="appIdentification()"
    [inputLeftBar]="leftbarConfig()"
    [i18nAppService]="i18nService"
  >
    <adm-current-org-card  leftbar-top-content
      [currentOrganization]="currentOrganization"
    ></adm-current-org-card>

    @if(ready()) {
      <main class="admin__container">
        <router-outlet></router-outlet>
      </main>
    }
  </evc-platform>
</div>
