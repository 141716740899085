import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { ToastService } from '../../services/toast/toast.service';
import type { IconListType } from '../../types/icon-list.type';
import type { ToastAction } from '../../types/toast.type';
import { ToastType, ToastTypes } from '../../types/toast.type';
import { Maybe } from '../../utils/types';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'evc-toast',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  @Output() readonly toastClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() type: ToastType = ToastTypes.INFO;
  @Input() message = '';
  @Input() icon: Maybe<IconListType> = undefined;
  @Input() action: Maybe<ToastAction> = undefined;
  @Input() id: Maybe<string> = undefined;
  readonly #toastService = inject(ToastService);

  closeToast(): void {
    this.toastClosed.emit();
    if (this.id) this.#toastService.remove(this.id);
  }
}
