<table mat-table [dataSource]="dataSource()" class="mat-elevation-z8" matSort>

  @if (selectableRows) {
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          [class.visible]="isSomeItemsSelected()"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  }

  @for (column of displayedColumns; track $index) {
    <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'sort-{{column}}'">
        <div class="evc-table__col-header">
          {{ column }}
        </div>
      </th>

      <td mat-cell *matCellDef="let element"
        [ngClass]="{'evc-table--clickable-cell' : element[columnMapping[column]].click ?? false}"
        (click)="onCellClick(element[columnMapping[column]])"
      >
        @if (element[columnMapping[column]]; as cellElement) {
          <div>
            <evc-cell [cellData]="cellElement"></evc-cell>
          </div>
        }
      </td>
    </ng-container>
  }

  @if (rowAction) {
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
          <button
            class="evc-table__action"
            (click)="rowAction.action(element, $event)"
          >
            <evc-svg-icon size="sm" icon="ellipsis" class="evc-table__action__icon"></evc-svg-icon>
          </button>
      </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="headerColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: headerColumns();"
    [ngClass]="{
      'evc-table--clickable-row' : clickableRows,
      selected : selection.isSelected(row)
    }"
    (click)="onRowClick(row)"
  ></tr>
</table>