import type { EvcFormErrorKey } from '../types/errors.type';

/** default messages used by validatos
 * you may override them using i18n `errors.${key}` path
 */
export const defaultErrorMessages:Record<EvcFormErrorKey|string, string> = {
  unknown: 'An error occured.',
  required: 'This field is required.',
  length: 'Invalid length.',
  maxlength: 'Too long.',
  minlength: 'Too short.',
  pattern: 'Invalid format.',
  validEmail: 'Invalid email address.',
  validPhone: 'Invalid phone number.',
  validZipCode: 'Invalid zip code.',
  // useful extra messages related to validations but not used by our validator
  noSpecialChars: 'No special chars allowed.',
};
