import type { OnInit, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';

import {
  NavButtonComponent,
  SvgIconComponent,
  TooltipHoverDirective,
 WebComponentsConfigService } from '@evc/web-components';

import { AuthService } from '../../core-client/auth/service/auth.service';
import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import { UserService } from '../../core-client/user/user.service';
import type { ComputedProduct } from '../../core-client/user/user.type';
import { PlatformTranslatePipe } from '../../pipes/platform-translate.pipe';
import { PlatformConfigService } from '../../services/config/config.service';
import type { ApplicationType } from '../../types/user-menu.type';

@Component({
  selector: 'evc-apps-menu',
  standalone: true,
  imports: [
    PlatformTranslatePipe,
    SvgIconComponent,
    NavButtonComponent,
    TooltipHoverDirective,
  ],
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsMenuComponent implements OnInit {
  #authService = inject(AuthService);
  #configService = inject(PlatformConfigService);
  #organizationService = inject(OrganizationsService);
  #userService = inject(UserService);

  applications: ApplicationType[] = [];
  readonly #webComponentConfigService = inject(WebComponentsConfigService);
  basePath = this.#webComponentConfigService.getComputed('ASSETS_BASE_PATH');

  #maybeForceLoginUriSuffix = computed(() => this.#authService.isAuthenticated()
    ? '/login'
    : '',
  );

  constructor() {
    this.applications = this.#configService.applications;
  }

  showAdminApp = computed(() => {
    const isNotAdminApp = this.#configService.get('APP_NAME') !== 'admin';
    const isAllowed = this.#organizationService.isCurrentUserAdminOrOwner();

    return isAllowed && isNotAdminApp;
  });

  computedApps: WritableSignal<ComputedProduct[] | ApplicationType[]> = signal([]);

  ngOnInit(): void {
    if (!this.#configService.greenfield) {
      this.computedApps.set(this.#configService.applications);
    } else {
      this.#userService.getUserApplications().then(apps => {
        this.computedApps.set(apps);
      });
    }
  }

  handleAppClick(app: ApplicationType | ComputedProduct): void {
    if (this.isComputedProduct(app)) {
      console.warn('[todo] implement handleAppClick for computed product');
    } else {
      window.open(app.launchUrl, '#blank');
    }
  }

  getAppsImgPath(app: ApplicationType): string {
    const appCode: string = app.code.toLowerCase();

    return `${this.basePath()}apps/${appCode}.svg#${appCode}`;
  }

  handleHomeClick(): void {
    const { homepage } = this.#configService.get('uris');
    if (!homepage) return;

    window.open(`${homepage}${this.#maybeForceLoginUriSuffix()}`, '#blank');
  }

  handleMarketplaceClick(): void {
    const { marketplace } = this.#configService.get('uris');
    if (!marketplace) return;

    window.open(`${marketplace}${this.#maybeForceLoginUriSuffix()}`, '#blank');
  }

  handleAdminClick(): void {
    const { admin } = this.#configService.get('uris');
    if (!admin) return;
    window.open(admin, '#blank');
  }

  isComputedProduct(app: ApplicationType | ComputedProduct): app is ComputedProduct {
    return (app as ComputedProduct).id !== undefined;
  }
}
