import { SelectionModel } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { Maybe, SvgIconComponent } from '@evc/web-components';

import { I18nService } from '../../services/i18n/i18n.service';
import { CellComponent } from './cell/cell.component';
import type { RowAction, TableCell, TableRow, TableRowArray } from './table.type';

@Component({
  standalone: true,
  selector: 'evc-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  imports: [NgClass, MatTableModule, MatSortModule, CellComponent, MatCheckboxModule, SvgIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  @Output() readonly rowClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();

  @Input() displayedColumns: string[] = [];
  @Input() columnMapping: { [key: string ]: string } = {};
  @Input() data!: WritableSignal<TableRowArray>;
  @Input() clickableRows = false;
  @Input() selectableRows = false;
  @Input() rowAction: Maybe<RowAction> = undefined;
  @Input() sortingDataAccessor!: (item: TableRow, property: string) => string;

  #i18nService = inject(I18nService);

  dataSource = computed(() => new MatTableDataSource(this.data()));
  selection = new SelectionModel<TableRow>(true, []);
  headerColumns = signal(this.displayedColumns);

  ngAfterViewInit(): void {
    this.dataSource = computed(() => new MatTableDataSource(this.data()));
    this.dataSource().sort = this.sort;
    this.dataSource().sortingDataAccessor = this.sortingDataAccessor;
    this.headerColumns.set(this.#setDisplayedColumns());
  }

  onRowClick(row: TableRow): void {
    this.rowClicked.emit(row);
  }

  onCellClick(cell: TableCell): void {
    if (cell.click) {
      cell.click();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource().data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();

      return;
    }

    this.selection.select(...this.dataSource().data);
  }

  isSomeItemsSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  checkboxLabel(row?: TableRow): string {
    if (!row) {
      return `${this.isAllSelected() ? this.#i18nService.t('actions.deselect_all') : this.#i18nService.t('actions.select_all')}`;
    }

    return `${this.selection.isSelected(row)
      ? this.#i18nService.t('actions.deselect_row')
      : this.#i18nService.t('actions.select_row')} ${this.dataSource().data.indexOf(row) + 1}`;
  }

  #setDisplayedColumns(): string[] {
    let rows = this.displayedColumns;
    if (this.selectableRows) {
      rows = ['select', ...rows];
    }

    if (this.rowAction) {
      rows = [...rows, 'actions'];
    }

    return rows;
  }
}
