<evc-gradient-border-mask class="mask mask--topbar" type="topbar">
  <div class="topbar">
    <evc-header
      class="topbar__title-and-tabs"
      [topTabs]="topbarConfig?.topTabs"
      [appIdentity]="topbarConfig?.appIdentity"
    ></evc-header>

    <div class="topbar__menus-container">
      @if(!!topbarConfig?.search){
        <div
          class="topbar__search-container"
          [ngClass]="{ 'topbar__search-container--closed': !showSearch }"
        >
          <evc-button type="icon" (click)="toggleSearch()" [variant]="showSearch ? 'round' : 'semi'">
            <evc-svg-icon size="lg" icon="magnifying-glass" basePath="{{basePath}}"></evc-svg-icon>
          </evc-button>

          <evc-search
            class="topbar__search-surface"
            [ngClass]="{ 'topbar__search-surface--closed': !showSearch }"
          ></evc-search>
        </div>
      }

      <evc-button
        type="icon"
        [selected]="showHelpMenu"
        (click)="toggleMenu('help-btn')"
        variant="semi"
      >
        <evc-svg-icon size="lg" icon="circle-question" basePath="{{basePath}}"></evc-svg-icon>
      </evc-button>

      <evc-button
        type="icon"
        [selected]="showSettingsMenu"
        (click)="toggleMenu('setting-btn')"
        variant="semi"
      >
        <evc-svg-icon size="lg" icon="gear-complex" basePath="{{basePath}}"></evc-svg-icon>
      </evc-button>

      <button
        class="topbar__user-badge"
        [class.topbar__user-badge--selected]="showUserMenu"
        (click)="toggleMenu('user-btn')"
      >
        @if(currentOrganization?.logo){
          <img
            class="topbar__user-badge--org-logo"
            [src]="currentOrganization?.logo"
            [alt]="currentOrganization?.name"
          />
        }
        @else {
          <span class="topbar__user-badge--org-name">{{ getCurrentOrganizationName() }}</span>
        }

        @if(userProfile(); as profile ){
          @if(profile.avatar.type === 'image'){
            <img
              class="topbar__user-badge--img"
              [src]="profile.avatar.src"
              [alt]="userProfile()!.displayName"
            />
          }
          @else {
            <span
              class="topbar__user-badge--badge"
              [ngStyle]="{ 'background-color': profile.avatar.color}"
            >
              {{ profile.avatar.initials }}
            </span>
          }
        } @else {
          <span class="topbar__user-badge--badge"></span>
        }
      </button>

      <evc-button
        type="icon"
        [selected]="showAppsMenu"
        (click)="toggleMenu('apps-btn')"
        variant="semi"
      >
        <evc-svg-icon size="lg" icon="choco-menu" basePath="{{basePath}}"></evc-svg-icon>
      </evc-button>
    </div>
  </div>
</evc-gradient-border-mask>

@if(showSettingsMenu){
  <evc-gradient-border-mask class="mask mask--setting" type="menu">
    <evc-dropdown-menu
      evcCloseOnClickOutside
      class="topbar__glassy-menu topbar__glassy-menu--setting"
      [menuConfig]="topbarConfig?.settingsMenu"
      [showDividers]="true"
      (clickedOutside)="handleHideMenu()"
      (itemClicked)="handleHideMenu()"
    ></evc-dropdown-menu>
  </evc-gradient-border-mask>
}

@if(showHelpMenu){
  <evc-gradient-border-mask class="mask mask--help" type="menu">
    <evc-dropdown-menu
      evcCloseOnClickOutside
      class="topbar__glassy-menu topbar__glassy-menu--help"
      [menuConfig]="topbarConfig?.helpMenu"
      [showDividers]="true"
      (clickedOutside)="handleHideMenu()"
      (itemClicked)="handleHideMenu()"
    ></evc-dropdown-menu>
  </evc-gradient-border-mask>
}

@if(showAppsMenu){
  <evc-apps-menu
    evcCloseOnClickOutside
    class="topbar__glassy-menu topbar__glassy-menu--apps"
    (clickedOutside)="handleHideMenu()"
  ></evc-apps-menu>
}

@if(showUserMenu){
  <evc-gradient-border-mask class="mask mask--user" type="menu">
    <evc-user-menu
      evcCloseOnClickOutside
      class="topbar__glassy-menu topbar__glassy-menu--user"
      (clickedOutside)="handleHideMenu()"
      (login)="handleLogin()"
      (logout)="handleLogout()"
    ></evc-user-menu>
  </evc-gradient-border-mask>
}

@if(getShownModal('about')){
  <evc-about-modal
    [appIdentity]="topbarConfig?.appIdentity"
    (hideModal)="hideModal()"
  ></evc-about-modal>
}

