/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { DestroyRef, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from 'platform/core-client/auth/auth.service';
import type { UserApi, UserApiPossibilities, UserAvatar, UserGreenfieldApi, UserProfile } from 'platform/core-client/user/user.type';
import { PlatformConfigService } from 'platform/services/config/config.service';
import { DefaultConfigService } from 'platform/services/default-config/default-config.service';
import type { Observable } from 'rxjs';
import { switchMap } from 'rxjs';

const DEFAULT_USER_COLOR = '#93C6F9';
const DEFAULT_USER_CHAR = '.';

export type OptionalProfile = UserProfile | undefined;
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _destroyRef = inject(DestroyRef);
  private _profile:WritableSignal<OptionalProfile> = signal(undefined);
  public get profile():Signal<OptionalProfile> {
    return this._profile.asReadonly();
  }

  public get isAvatarImage():boolean {
    return this.profile()?.avatar?.type === 'image';
  }
  public get isAdmin():boolean {
    return this.profile()?.role === 'admin';
  }

  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _config: PlatformConfigService,
    private readonly _defaultConfigService: DefaultConfigService,
  ) {
    if (_config.greenfield) {
      this.autoFetchProfile();
    }
  }

  public setUser(user: UserApi|undefined):void {
    const profile = user ? this.computeProfile(user) : undefined;
    this._profile.set(profile);
  }

  /** fetch user info
   * - remap data to fit our app model
   * - then update our profile
   */
  public updateProfile():void {
    this.fetchUser$()
    .subscribe({
      next: (userProfile) => {
        this.setUser(userProfile);
      },
      error: (_error) => {
        this.setUser(undefined);
      },
    });
  }

  private autoFetchProfile() {
    effect(() => {
      const token = this._auth.accessToken();
      if (!token) return;
      this.updateProfile();
    });
  }

  private fetchUser$() :Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.user}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.get<UserGreenfieldApi>(apiUri)),
    );
  }

  private computeProfile(data: UserApi): UserProfile {
    const user = data as UserApiPossibilities;
    const id = user.id ?? user.tenantId;
    const role = user.role ?? 'user';

    const fromDisplayName = (user.displayName && user.displayName !== 'unknown') && user.displayName;
    const fromFormalName = (user.givenName || user.surname) && [user.surname, user.givenName].join(' ');
    const displayName = fromDisplayName || fromFormalName
      || user.email?.split('@')[0]?.split(/[.,+-]/).join(' ') || id;
    const { email } = user;

    return {
      id,
      displayName,
      email,
      role,
      avatar: this.computeAvatar({ ...user, formalName: fromFormalName || displayName }),
    };
  }

  private computeAvatar(user: UserApiPossibilities): UserAvatar {
    const { formalName, avatar, color } = user;
    if (avatar) {
      return {
        type: 'image',
        src: avatar,
      };
    }

    const initials = this.computeInitials(formalName!);

    const colorDefaulted = color ?? DEFAULT_USER_COLOR;
    // TODO compute light/dark text
    const isLight = !!colorDefaulted;

    return {
      type: 'initials',
      color: colorDefaulted,
      light: isLight,
      initials,
    };
  }

  private computeInitials(formalName: string): string {
    const words = formalName.split(' ') ?? [];

    const firstWord = (words.length > 0 && words[0]) || DEFAULT_USER_CHAR;
    const lastWord = words.length > 1 && words[words.length - 1];

    const firstWordLetterOne = (firstWord.length > 0 && firstWord[0].toLocaleUpperCase()) || DEFAULT_USER_CHAR;
    const firstWordLetterTwo = (firstWord.length > 1 && firstWord[1].toLocaleLowerCase()) || DEFAULT_USER_CHAR;
    const lastWordInitial = (lastWord && lastWord[0].toLocaleUpperCase()) || firstWordLetterTwo;

    return [
      firstWordLetterOne,
      lastWordInitial,
    ].join('');
  }

  public openProfile():void {
    const uri = this._defaultConfigService.appsLinks?.user;
    if (!uri) return;
    window.open(this._defaultConfigService.appsLinks.user, '_blank');
  }
}
