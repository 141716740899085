/**
 * Helper to inject environment variables in Angular
 * please inject this provider in the app.config.ts as we use this within our libs
 * used to pass .env from app to lib services using injectable
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { provideEnv } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideEnv(environment),
 *   ],
 * };
 * ```
 *
 * @example some.service.ts
 * ```ts
 * import { ENV_VARIABLES } from '@evc/platform';
 * import type { SharedEnvironment } from '@shared/services/config/config.type';
 * export class SomeService {
 *   constructor(
 *     @Inject(ENV_VARIABLES) environment: SharedEnvironment,
 *   ) {
 *     console.log('ENV_VARIABLES', environment);
 *   }
 * }
 * ```
 */
import { InjectionToken } from '@angular/core';
import type { PlatformEnv } from 'platform/providers/env.type';

/* way to inject config from a callback to pass .env config */
export const ENV_VARIABLES = new InjectionToken<string>('.env vars');

export type EnvProvider = {
  provide: typeof ENV_VARIABLES;
  useFactory: () => PlatformEnv
}
export function provideEnv(environment:PlatformEnv):EnvProvider {
  return {
    provide: ENV_VARIABLES,
    useFactory: () => environment,
  };
}
