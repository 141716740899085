import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';

import type { UserOrganization } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { AvatarComponent } from '@evc/web-components';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';

@Component({
  selector: 'adm-current-org-card',
  templateUrl: './current-org-card.component.html',
  imports: [AvatarComponent, TranslatePipe],
  standalone: true,
  styleUrls: ['./current-org-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentOrgCardComponent {
  @Input() currentOrganization!: Signal<Maybe<UserOrganization>>;
}
