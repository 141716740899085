import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'evc-page-two',
  templateUrl: 'page-two.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTwoComponent {

}
