import { JsonPipe } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { LeftbarComponent } from 'platform/components/leftbar/leftbar.component';
import { TopbarComponent } from 'platform/components/topbar/topbar.component';
import { AuthService } from 'platform/core-client/auth/auth.service';
import { OrganizationsService } from 'platform/core-client/organizations/organizations.service';
import { UserService } from 'platform/core-client/user/user.service';
import type { UserApi, UserApiPossibilities } from 'platform/core-client/user/user.type';
import { PlatformConfigService } from 'platform/services/config/config.service';
import { DefaultConfigService } from 'platform/services/default-config/default-config.service';
import { AppIdentity } from 'platform/types/app-identity.type';
import { AppLinks } from 'platform/types/app-links.type';
import type { TopbarConfigType } from 'platform/types/topbar-config.type';
import type { ApplicationType } from 'platform/types/user-menu.type';

import type {
  BadgeItemType,
  Menu,
  MenuItemType,
  ThemeType,
} from '@evc/web-components';
import { AvailableThemes, NavButtonComponent, SvgIconComponent, ThemeService } from '@evc/web-components';

@Component({
  selector: 'evc-platform',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgIconComponent, LeftbarComponent, NavButtonComponent, TopbarComponent, JsonPipe],
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
})
export class PlatformComponent implements OnChanges, OnInit {
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly login: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly selectOrganization: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly isLeftbarOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() public inputAppIdentification?: AppIdentity;
  @Input() public inputHelpMenu?: MenuItemType[];
  @Input() public inputSettingsMenu?: MenuItemType[];
  @Input() public inputTopTabs?: MenuItemType[];
  @Input() public inputLeftBar?: Menu[];
  @Input() public inputLinks?: AppLinks;

  @Input() public inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined;
  @Input() public inputSearch?: (...args: string[]) => void | undefined;
  @Input() public basePath = '/';

  public config!: TopbarConfigType;

  @Input() public set inputUserProfile(user: UserApi|undefined) {
    if (this._config.greenfield) throw new Error('inputUserProfile is not available in greenfield mode');

    if (user) {
      const { applications } = user as UserApiPossibilities;
      if (applications) {
        this.inputUserApplications = applications;
      }
      const { organizations } = user as UserApiPossibilities;
      if (organizations) {
        this.inputUserOrganizations = organizations as BadgeItemType[];
      }
    }

    this._userService.setUser(user);
  }

  @Input() public set inputUserOrganizations(organizations: BadgeItemType[]) {
    if (!organizations?.length) {
      return;
    }
    this._organizationsService.setEntries(organizations);
  }

  @Input() public set inputUserApplications(apps: ApplicationType[]) {
    this._config.setApplications(apps);
  }

  constructor(
    private readonly _userService: UserService,
    private readonly _themeService: ThemeService,
    private readonly _defaultConfigService: DefaultConfigService,
    public readonly _organizationsService: OrganizationsService,
    private _auth: AuthService,
    private _config: PlatformConfigService,
  ) {
    this.initTopbarConfig();
    this.emitSelectOrganizationOnChange();
  }

  ngOnInit(): void {
    this._auth.init();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined =
      changes.inputForcedTheme?.currentValue;

    this.changeTheme(inputForcedTheme);

    if (changes.inputSearch?.currentValue) {
      this.config = { ...this.config, search: changes.inputSearch?.currentValue };
    }

    if (changes.inputAppIdentification?.currentValue) {
      const inputIdentification: AppIdentity = changes.inputAppIdentification?.currentValue;
      if (inputIdentification.appLogo && inputIdentification.appTitle$) {
        this.config = { ...this.config, appIdentity: inputIdentification };
      }
    }

    if (changes.inputHelpMenu?.currentValue) {
      const inputHelpMenu: MenuItemType[] = changes.inputHelpMenu?.currentValue;
      this.updateHelpMenuItems(inputHelpMenu);
    }

    if (changes.inputSettingsMenu?.currentValue) {
      const inputSettingsMenu: MenuItemType[] = changes.inputSettingsMenu?.currentValue;
      this.updateSettingsMenuItem(inputSettingsMenu);
    }

    if (changes.inputTopTabs?.currentValue) {
      const inputTopTabs: MenuItemType[] = changes.inputTopTabs?.currentValue;
      this.config = { ...this.config, topTabs: inputTopTabs };
    }

    if (changes.inputLeftBar?.currentValue) {
      const inputLeftBar: Menu[] = changes.inputLeftBar?.currentValue;
      this.config = { ...this.config, leftBar: inputLeftBar };
    }

    if (changes.inputLinks?.currentValue) {
      this._defaultConfigService.appsLinks = changes.inputLinks?.currentValue;
    }

    if (changes.basePath?.currentValue) {
      this._defaultConfigService.basePath = this.basePath;
    }
  }

  public handleLogin(): void {
    if (this._config.greenfield) {
      this._auth.login();
    } else {
      this.login.emit();
    }
  }

  public handleLogout(): void {
    if (this._config.greenfield) {
      this._auth.logout();
    } else {
      this.logout.emit();
    }
  }

  public handleToggleLeftBar(isOpen: boolean): void {
    this.isLeftbarOpenChange.emit(isOpen);
  }

  private emitSelectOrganizationOnChange(): void {
    // ignore 1st call because it's the initial value - not a change
    // could be improved using https://stackoverflow.com/questions/76200595/possible-to-access-the-old-value-in-angular-16s-effect-function-similar-to-v
    let first = true;
    effect(() => {
      const orgId = this._organizationsService.currentOrgId();
      if (first) {
        first = false;

        return;
      }
      this.selectOrganization.emit(orgId);
    }, { allowSignalWrites: true });
  }

  private updateSettingsMenuItem(items: MenuItemType[]) {
    const updatedItems = this.mergeMenuItems(this._defaultConfigService.DEFAULT_SETTING_MENU.items, items);
    this.config = {
      ...this.config,
      settingsMenu: { ...this.config.settingsMenu, items: updatedItems },
    };
  }

  private updateHelpMenuItems(items: MenuItemType[]) {
    const updateHelpItems = this.mergeMenuItems(this._defaultConfigService.DEFAULT_HELP_MENU.items, items);
    this.config = {
      ...this.config,
      helpMenu: { ...this.config.helpMenu, items: updateHelpItems },
    };
  }

  private changeTheme(inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined): void {
    if (!inputForcedTheme) {
      if (!this.config.theme) {
        this._themeService.changeTheme('auto');
        this.config.theme = 'auto';
      }

      return;
    }

    if (!AvailableThemes.includes(inputForcedTheme)) {
      console.error(new Error('Given theme is unavailable - theme set to "auto"'));
    } else {
      this.config.theme = inputForcedTheme as ThemeType;
      this._themeService.changeTheme(inputForcedTheme as ThemeType);
      // remove themes from the default setting menu
      this.config.settingsMenu.items = this.config.settingsMenu.items?.filter(
        item => item.key !== 'theme',
      );
    }
  }

  private initTopbarConfig(): void {
    this.config = {
      appIdentity: {
        appTitle$: this._defaultConfigService.DEFAULT_APP_TITLE,
        appLogo: this._defaultConfigService.DEFAULT_APP_LOGO,
      },
      helpMenu: this._defaultConfigService.DEFAULT_HELP_MENU,
      settingsMenu: this._defaultConfigService.DEFAULT_SETTING_MENU,
    };
  }

  private mergeMenuItems(defaultItems: MenuItemType[] | undefined, inputItems: MenuItemType[]): MenuItemType[] {
    const itemMap: Map<string, MenuItemType> = new Map<string, MenuItemType>();
    defaultItems?.forEach((item: MenuItemType) => {
      itemMap.set(item.key, item);
    });

    inputItems.forEach((item: MenuItemType) => {
      itemMap.set(item.key, item);
    });
    defaultItems = Array.from(itemMap.values());

    return defaultItems;
  }
}
