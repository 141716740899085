import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from 'platform/core-client/user/user.service';
import { PlatformConfigService } from 'platform/services/config/config.service';

import {
  CloseOnClickOutsideDirective,
  NavButtonComponent,
  SvgIconComponent,
  TooltipHoverDirective,
} from '@evc/web-components';

import { DefaultConfigService } from '../../services/default-config/default-config.service';
import type { ApplicationType } from '../../types/user-menu.type';

@Component({
  selector: 'evc-apps-menu',
  standalone: true,
  imports: [
    CloseOnClickOutsideDirective,
    SvgIconComponent,
    NavButtonComponent,
    TooltipHoverDirective,
  ],
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsMenuComponent {
  public applications: ApplicationType[] = [];

  constructor(
    private _config: PlatformConfigService,
    private _defaultConfigService: DefaultConfigService,
    private _user:UserService,
  ) {
    this.applications = this._config.applications;
  }

  public isUserAdmin(): boolean {
    return this._user.isAdmin;
  }

  public handleAppClick(app: ApplicationType): void {
    window.open(app.launchUrl, '_blank');
  }

  public getMoreApps(): void {
    window.open(this._defaultConfigService.appsLinks.marketplace, '_blank');
  }

  public getAppsImgPath(app: ApplicationType): string {
    const appCode: string = app.code.toLowerCase();

    return `/assets/apps/${appCode}.svg#${appCode}`;
  }

  public handleAdminClick(): void {
    window.open(this._defaultConfigService.appsLinks.admin, '_blank');
  }
}
