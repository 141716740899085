  <evc-modal
    class="invitation-modal"
    evcCloseOnClickOutside
    width="33%"
    maxWidth="480px"
    minWidth="320px"
    [showReduceButton]="false"
    (clickedOutside)="toggleModal()"
    (closeModal)="toggleModal()"
  >

    <span modal-title>{{ 'labels.invite_users' | translate }}</span>

    <div modal-content>
      <form name="invitation-form"  class="invitation-modal__modal-content" [formGroup]="form">
        <div>
          <span class="invitation-modal__section-title">{{ 'PAGE_TEAM.invitation.user' | translate }}</span>
          <evc-input #userEmailInput formControlName="email" label="{{ 'PAGE_TEAM.invitation.enter_email' | translate }}" [showError]="showError()"></evc-input>
        </div>

        <div class="invitation-modal__role-section">
          <div class="invitation-modal__role-section__header">
            <span class="invitation-modal__section-title invitation-modal__label">{{ 'PAGE_TEAM.invitation.role' | translate }}</span>
            <a href="{{'links.privacy_notice.href' | translate}}" class="invitation-modal__link" target="_blank">
              {{ 'links.privacy_notice.text' | translate }}
              <evc-svg-icon icon="circle-question" size="xs"></evc-svg-icon>
            </a>
          </div>
          <evc-input type="select" formControlName="role" [options]="roleOptions"></evc-input>
        </div>

        <evc-info-banner
          class="secu-privacy__info-banner"
          type="info"
          [closable]="false"
        >
          <div banner-content class="invitation-modal__info-banner">
            <span>
              {{ bannerMessage() }}
            </span>

            <a href="{{'links.terms_of_use.href' | translate}}" class="invitation-modal__link" target="_blank">
              {{ 'links.terms_of_use.text' | translate }}
              <evc-svg-icon icon="arrow-up-right-from-square" size="xs"></evc-svg-icon>
            </a>
          </div>
        </evc-info-banner>
      </form>
    </div>

    <div modal-actions class="invitation-modal__modal-actions">
      <evc-button
        type="tertiary"
        variant="semi"
        (click)="toggleModal()"
      >
        <span class="invitation-modal__modal-actions--cancel">{{'actions.cancel' | translate }}</span>
      </evc-button>
      <evc-button
        type="primary"
        variant="semi"
        (click)="submitForm($event)"
      >
        {{ 'actions.send_invitation' | translate }}
      </evc-button>
    </div>
  </evc-modal>
