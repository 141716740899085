import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SharedConfigService } from '../../services/config/config.service';

@Component({
  selector: 'test-shared',
  standalone: true,
  imports: [CommonModule],
  template: `<p class="test-shared">
    shared works!
    <br />- env: {{env}}
    <br/>- shared styles works if bg green (need to import shared index.scss)
  </p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestSharedComponent {
  public env: string;
  constructor(
    _configService: SharedConfigService,
  ) {
    this.env = _configService.get('env');
  }
}
