import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import {
  NavButtonComponent,
  SvgIconComponent,
  TooltipHoverDirective,
 WebComponentsConfigService } from '@evc/web-components';

import { UserService } from '../../core-client/user/user.service';
import { PlatformTranslatePipe } from '../../pipes/platform-translate.pipe';
import { PlatformConfigService } from '../../services/config/config.service';
import type { ApplicationType } from '../../types/user-menu.type';

@Component({
  selector: 'evc-apps-menu',
  standalone: true,
  imports: [
    PlatformTranslatePipe,
    SvgIconComponent,
    NavButtonComponent,
    TooltipHoverDirective,
  ],
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsMenuComponent {
  public applications: ApplicationType[] = [];
  readonly #webComponentConfigService = inject(WebComponentsConfigService);
  basePath = this.#webComponentConfigService.getComputed('ASSETS_BASE_PATH');

  constructor(
    private _configService: PlatformConfigService,
    private _userService: UserService,
  ) {
    this.applications = this._configService.applications;
  }

  public isUserAdmin(): boolean {
    return this._userService.profile()?.roles?.includes('admin')
      || this._configService.get('DEBUG_ALWAYS_SHOW_ADMIN_APP')
      || false;
  }

  public handleAppClick(app: ApplicationType): void {
    window.open(app.launchUrl, '_blank');
  }

  public getAppsImgPath(app: ApplicationType): string {
    const appCode: string = app.code.toLowerCase();

    return `${this.basePath()}apps/${appCode}.svg#${appCode}`;
  }

  public handleMarketplaceClick(): void {
    const { marketplace } = this._configService.get('uris');
    if (!marketplace) return;
    window.open(marketplace, '_blank');
  }

  public handleAdminClick(): void {
    const { admin } = this._configService.get('uris');
    if (!admin) return;
    window.open(admin, '_blank');
  }
}
