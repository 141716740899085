import type { Observable } from 'rxjs';

import type { IconType } from './icon-list.type';
import type { BadgeItemType } from './organization.type';

export enum BtnActionEnum {
  CALLBACK = 'interactive',
  URL = 'url',
}

export type MenuItemType = {
  key: string;
  text: string | Observable<string>;
  icon?: IconType;
  img?: string;
  isDisabled?: boolean;
  selected?: boolean;
  collapsible?: MenuItemType[];
  organizationList?: BadgeItemType[];
} & (ActionCallback | ActionURL);

type ActionCallback = {
  action?: {
    type: BtnActionEnum.CALLBACK;
    callbackOrUrl: () => void;
  };
};

type ActionURL = {
  action?: {
    type: BtnActionEnum.URL;
    callbackOrUrl: string;
  };
};
