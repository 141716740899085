import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard } from '@evc/platform';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

import { PageAppLicensesComponent } from './pages/app-licenses/app-licenses.component';
import { PageLinkedOrgsComponent } from './pages/linked-orgs/linked-orgs.component';
import { PageOrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { PageUsersComponent } from './pages/users/users.component';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

export const appRoutes: Route[] = [
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    component: PageNotFoundComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: PageUsersComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'app-licenses',
    component: PageAppLicensesComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'linked-organizations',
    component: PageLinkedOrgsComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'organization-settings',
    component: PageOrgSettingsComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
];
