import { Injectable } from '@angular/core';

import type { ThemeType } from '../../types/theme.type';
import { AvailableThemes } from '../../types/theme.type';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public changeTheme(theme: ThemeType): void {
    const htmlElement: HTMLElement | null = document.querySelector('html');
    if (htmlElement) {
      htmlElement.classList.remove(
        ...AvailableThemes.map(possibleTheme => `${possibleTheme}-theme`),
      );
      htmlElement.classList.add(`${theme}-theme`);
    }
  }
}
