<div class="toast toast--{{type}}">
  <div class="toast__section">
    @if (icon) {
      <div class="toast__icon">
        <evc-svg-icon [icon]="icon" size="md"></evc-svg-icon>
      </div>
    }
    <span class="toast__message">{{message}}</span>
  </div>

  <div class="toast__section toast__section--actions">
    @if (action) {
      <button class="toast__action toast__action--{{type}}" (click)="action.callback()">
        <span>{{action.text}}</span>
      </button>
    }

    <evc-svg-icon icon="xmark" size="md" class="toast__close-icon toast__close-icon--{{type}}" (click)="closeToast()"></evc-svg-icon>
  </div>

</div>