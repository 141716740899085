import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconListType } from '../../types/icon-list.type';
import { NavButtonComponent } from '../nav-button/nav-button.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  standalone: true,
  selector: 'evc-info-banner',
  templateUrl: 'info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  imports: [SvgIconComponent, NavButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBannerComponent {
  @Output() public readonly bannerClosed: EventEmitter<void> = new EventEmitter<void>();

  @Input() public type: 'info' | 'warn' | 'error' | 'notif' = 'info';
  @Input() public closable = true;
  @Input() public message = '';
  @Input() public icon?: IconListType;

  public closeBanner(): void {
    this.bannerClosed.emit();
  }
}
