@if(item){
  <button
    class="item"
    [ngClass]="{ 'item--selected': selected }"
    [disabled]="item.isDisabled"
    (click)="handleItemClick(item)"
  >
    <div class="item__info">
      @if(item.img){
        <img #imageBlock class="item__img" [src]="item.img" alt="theme image" />
      }
      @if(item.icon){
        <evc-svg-icon size="md" [icon]="item.icon"></evc-svg-icon>
      }
      <span>{{ item.text | optionalAsync }}</span>
    </div>

    @if(isActionURL(item)){
      <evc-svg-icon
        size="md"
        icon="arrow-up-right-from-square"
      ></evc-svg-icon>
    }
  </button>
}


@if(badgeItem){
  <button
    class="item item--organization"
    [ngClass]="{ 'item--selected': selected }"
    (click)="handleOrganizationClick(badgeItem)"
  >
    <div class="item__info">
      <span class="item__badge"> {{ initials }}</span>
      <span>{{ badgeItem.name }}</span>
    </div>
  </button>
}

