<div class="apps-menu">
  <div class="apps-menu__title">
    <span>{{ 'menus.applications.title' | platformTranslate }}</span>
    <evc-button
      type="link"
      rightIcon="house-blank"
      (click)="handleHomeClick()">
      <span>{{ 'menus.applications.links.home' | platformTranslate }}</span>
    </evc-button>
  </div>

  <div class="apps-menu__applications">
    @for (app of computedApps(); track $index) {
      <button
        class="apps-menu__item"
        [attr.data-name]="app.name"
        [evcTooltipHover]="app.name"
        (click)="handleAppClick(app)"
      >
        <div class="apps-menu__app-badge">
          @if(isComputedProduct(app)) {
            <div [innerHTML]="app.iconHtml" class="icon"></div>
          }
          @else {
            <evc-svg-icon size="logo" [svgPath]="getAppsImgPath(app)"></evc-svg-icon>
          }
        </div>
        <span class="apps-menu__app-name">{{ app.name }}</span>
      </button>
    }
    @if(showAdminApp()) {
      <button
        class="apps-menu__item"
        data-name="admin"
        [evcTooltipHover]="'admin'"
        (click)="handleAdminClick()"
      >
        <div class="apps-menu__app-badge apps-menu__gray-app">
          <evc-svg-icon size="logo" svgPath="{{ basePath() }}apps/admin.svg#admin"></evc-svg-icon>
        </div>
        <span class="apps-menu__app-name">{{ 'menus.applications.links.admin' | platformTranslate }}</span>
      </button>
    }
  </div>
  <evc-button class="apps-menu__more-apps-btn" type="cta" size="xxl" (click)="handleMarketplaceClick()">
    <span class="apps-menu__btn-text">{{ 'menus.applications.links.marketplace' | platformTranslate }}</span>
  </evc-button>
</div>
