/**
 * Platform service
 *
 * handle loading flow of platform services
 * - init all needed services
 * - please check this graph to understand the flow : https://evident-products.atlassian.net/wiki/spaces/WSCLOUD/pages/111772838/ADR+-+Authentification+--+Frontend+implementation#Application-Auth-Flow-(greenfield)
 */
import type { Signal } from '@angular/core';
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivationStart, ResolveEnd, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { catchError, filter, first, firstValueFrom, map, of, take, timeout } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import { AuthService } from './core-client/auth/service/auth.service';
import { OrganizationsService } from './core-client/organizations/organizations.service';
import { UserService } from './core-client/user/user.service';
import { PlatformConfigService } from './services/config/config.service';
import { I18nPlatformService } from './services/i18n/i18n.service';
import { LanguageService } from './services/language/language.service';
import type { LangChangeEvent } from './services/language/language.type';
import type { AbstractI18nService } from './types/i18n-service.type';
import type { PlatformInitStatus } from './types/platform-init-status.type';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  #destroyRef = inject(DestroyRef);
  #authService = inject(AuthService);
  #userService = inject(UserService);
  #organizationsService = inject(OrganizationsService);
  #languageService = inject(LanguageService);
  #i18nPlatformService = inject(I18nPlatformService);
  #platformConfigService = inject(PlatformConfigService);
  #router = inject(Router);

  /** because outside of platform, we handle it if provided */
  #i18nAppService:Maybe<AbstractI18nService>;

  #ready = signal(false);
  get ready():Signal<boolean> {
    return this.#ready.asReadonly();
  }
  ready$ = toObservable(this.ready)
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((ready) => ready),
    );

  #userLang$ = toObservable(this.#userService.profile)
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((profile) => !!profile),
      map((profile) => profile!.preferredLanguage),
    );

  async init(i18nAppService?:Maybe<AbstractI18nService>):Promise<PlatformInitStatus> {
    this.#i18nAppService = i18nAppService;

    const needAuth:boolean = this.#platformConfigService.greenfield;
    const debug:boolean = this.#platformConfigService.get('DEBUG_LOADING_FLOW') ?? false;

    const waitRoute = this.#waitFirstRoute(needAuth);

    // KEEP THIS - usefull to debug main flow
    const log = (name:string) => (value:any) => {// eslint-disable-line @typescript-eslint/no-explicit-any
      if (debug) console.log(`[platform] flow : ${name}`, value);// eslint-disable-line no-console

      return value;
    };

    return this.#waitMaybeConnected(needAuth).then(log('connected'))
      .then((connected:boolean) => Promise.all([
          this.#waitAuthComplete(connected).then(log('auth')),
          this.#waitLanguage(connected).then(log('lang')),
        ])
        .then(([toid, lang]) => ({ needAuth, connected, toid, lang } as Omit<PlatformInitStatus, 'ready' | 'i18n' | 'route'>)),
      )
      .then((status:Omit<PlatformInitStatus, 'ready' | 'i18n' | 'route'>) => this.#waitI18n().then(log('i18n'))
        .then((i18n) => ({ ...status, ...i18n } as Omit<PlatformInitStatus, 'ready' | 'route'>),
      ))
      .then((status:Omit<PlatformInitStatus, 'ready' | 'route'>) => waitRoute.then(log('route'))
        .then((route) => ({ ...status, route } as Omit<PlatformInitStatus, 'ready'>),
      ))
      .then((status:Omit<PlatformInitStatus, 'ready'>) => {
        this.#ready.set(true);

        return status;
      })
      .catch((error) => {
        console.error('PlatformService init error', error);
      })
      .then((status) => ({ ...status,
        ready: this.ready(),
      } as PlatformInitStatus)).then(log('ready'));
  }

  /** wait connection (fetch accessToken) if needed
   * - skip if not greenfield
   * - succeed after user token - but auth complete flow still in progress
   *   => may trigger init userService
   *   @see waitAuthComplete 👇
   * @returns connected:boolean (if true will have to wait auth complete)
   */
  async #waitMaybeConnected(withAuth:boolean):Promise<boolean> {
    if (!withAuth) return Promise.resolve(false);

    const connected = await this.#waitMaybeLogoutRoute()
      .then((isLogout:boolean) => {
        if (isLogout) return false;

        return this.#authService.init();
      });

    // trigger profile load but no wait
    // will set user language - maybe needed to wait correct lang
    if (connected) {
      this.#userLang$
        .subscribe((lang) => {
          this.#languageService.setUserLanguage(lang);
        });

      this.#userService.init()
      .then((profile) => {
        this.#authService.loginHint = profile?.email;

        return profile;
      });
    }

    return connected;
  }

  /** after connected, wait for auth complete
   * - if reached, either user have organizations or we do not enforce them
   * - may trigger init organizationService
   * @returns toid:string (current organizationId) or false
   */
   async #waitAuthComplete(withAuth:boolean):Promise<string|false> {
    if (!withAuth) return Promise.resolve(false);

    const flow:Observable<boolean> = this.#authService?.onComplete$ ?? of(false);

    return firstValueFrom(flow)
      .then((isAuthenticated) => {
        const toid = isAuthenticated && this.#authService.organizationId();
        if (toid) this.#organizationsService.init(toid);

        return toid ?? false;
      });
  }

  /** We finally guesse language to use
   * (may wait user or try many sources)
   * - init i18nService to load translation files
   * @returns lang:string (current lang to use)
   */
   async #waitLanguage(withAuth:boolean):Promise<string> {
      // sync lang change for later
      this.#languageService.lang$
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((lang:string) => {
          this.#authService.language = lang;
          this.#i18nPlatformService.setLanguage(lang);
          this.#i18nAppService?.setLanguage(lang);
        });

      return this.#languageService.init(withAuth);
  }

  /** after language, fetch translation messages
   * - now transtations ready to use
   * @returns {lang:string, translations:[]} | false
   */
   async #waitI18n():Promise<LangChangeEvent|false> {
    const flow = this.#i18nAppService?.onReady$ ?? of<false>(false);

    return Promise.all([
      firstValueFrom<LangChangeEvent|false>(flow),
      firstValueFrom(this.#i18nPlatformService.onReady$),
    ]).then(([event]) => event);
  }

  /** wait for the first route to be resolved
   * @returns Promise<void>
   */
  async #waitFirstRoute(needAuth:boolean):Promise<boolean> {
    const observable = needAuth
    ? this.#router.events.pipe(
        filter(event => event instanceof ResolveEnd),
        first(),
        map(() => true),
        timeout(6000),
        catchError((error) => {
          console.error('PlatformService #waitFirstRoute timeout', error);

          return of(false);
        }),
      )
    : of(true);

    return firstValueFrom(observable);
  }

  /** wait route data to check if logout (would cancel init auth flow)
   */
  async #waitMaybeLogoutRoute():Promise<boolean> {
    const observable = this.#router.events
    .pipe(
      filter((event) => event instanceof ActivationStart),
      map(event => event as ActivationStart),
      takeUntilDestroyed(this.#destroyRef),
      take(1),
      map(route => route?.snapshot.data?.logout ?? false),
      timeout(1000),
      catchError((error) => {
        console.error('PlatformService #waitMaybeLogoutRoute timeout', error);

        return of(false);
      }),
    );

    return firstValueFrom(observable);
  }
}
