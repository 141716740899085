/**
 * Simple Config service
 * - import environment variables
 * - then compute some values for some clean config
 */
import { Inject, Injectable, Optional } from '@angular/core';

import { ENV_VARIABLES, PlatformConfigService } from '@evc/platform';

import type { ConfigI18n, SharedConfig, SharedConfigOption } from './config.type';
import { SharedEnvironment } from './config.type';

@Injectable({
  providedIn: 'root',
})
export class SharedConfigService {
  private _config: SharedConfig;
  public get config(): SharedConfig {
    return { ...this._config };
  }
  public get<K extends SharedConfigOption>(key: K):SharedConfig[K] {
    return this.config[key];
  }

  constructor(
    @Optional() @Inject(ENV_VARIABLES) _environment: SharedEnvironment,
    _platformConfig: PlatformConfigService,
  ) {
    const environment = _environment || _platformConfig.config || {};

    this._config = {
      ...environment,
      version: environment.VERSION,
      ..._platformConfig.config,
      auth: _platformConfig.get('auth'),
      i18n: this.computeConfigI18n(environment),
    };
  }

  private computeConfigI18n(environment:SharedEnvironment):ConfigI18n {
    const locales = environment.LOCALES ?? [];
    const storageKey = environment.LOCALES_STORAGE_KEY;
    const languages = locales.map((locale:string) => locale.split('-')[0]);

    return {
      locales,
      storageKey,
      languages,
      defaultLang: languages[0],
    };
  }
}
