/**
 * Platform Config Service
 * this will use .env vars + some default config to compute clean config needed for our platform
 */
import { Inject, Injectable, Optional } from '@angular/core';
import { AUTH_CONFIG } from 'platform/core-client/auth/auth.providers';
import { ENV_VARIABLES } from 'platform/providers/env.providers';
import { PlatformEnv } from 'platform/providers/env.type';
import type { PlatformConfig, PlatformConfigOption } from 'platform/services/config/config.type';
import { AuthConfig } from 'platform/services/config/config.type';
import type { ApplicationType } from 'platform/types/user-menu.type';

@Injectable({
  providedIn: 'root',
})
export class PlatformConfigService {
  private _config: PlatformConfig;
  public get config(): PlatformConfig {
    return { ...this._config };
  }
  public get<K extends PlatformConfigOption>(key: K):PlatformConfig[K] {
    return this.config[key];
  }

  public get applications(): ApplicationType[] {
    const applications = this.get('applications') ?? [];

    return applications;
  }

  /** may use directly auth etc stuff only if greenfield
   * if custom, simply use Outputs to be intercepted via the host app
   */
  public get greenfield():boolean {
    return this.config.mode === 'greenfield';
  }
  constructor(
    @Optional() @Inject(ENV_VARIABLES) environment: PlatformEnv,
    @Optional() @Inject(AUTH_CONFIG) _auth: AuthConfig,
  ) {
    const { api, auth, ENV } = environment ?? { ENV: 'production' };

    const mode = (api && auth) ? 'greenfield' : 'custom';
    this._config = {
      ...environment,
      env: ENV,
      mode,
      api,
      auth: _auth,
    };
  }

  // May be defined via user config but it is more about topbar config than user one
  public setApplications(apps: ApplicationType[]):void {
    this._config.applications = apps;
  }
}
