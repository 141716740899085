import type { Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';

import type { BadgeItemType, ValueOf } from '@evc/web-components';
import {
  MenuItemComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { AuthService } from '../../core-client/auth/service/auth.service';
import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import type { UserOrganization } from '../../core-client/organizations/organizations.type';
import { UserService } from '../../core-client/user/user.service';
import type { UserProfile } from '../../core-client/user/user.type';
import { CoreClientUtilService } from '../../core-client/utils/core-client-utils.service';
import { PlatformTranslatePipe } from '../../pipes/platform-translate.pipe';
import { I18nPlatformService } from '../../services/i18n/i18n.service';

const PERIODS_OF_DAY = {
  ['3']: 'morning',
  ['12']: 'afternoon',
  ['17']: 'evening',
} as const;
export type PeriodOfDay = ValueOf<typeof PERIODS_OF_DAY>

@Component({
  selector: 'evc-user-menu',
  standalone: true,
  imports: [
    MenuItemComponent,
    SvgIconComponent,
    NavButtonComponent,
    PlatformTranslatePipe,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Output() readonly logout = new EventEmitter<void>();
  @Output() readonly login = new EventEmitter<void>();

  @Output() readonly logoutClick = new EventEmitter<void>();

  MAX_ORGANIZATIONS_BEFORE_SCROLL = 3;

  userProfile: Signal<UserProfile|undefined>;
  organizations: Signal<BadgeItemType[]>;

  #authService = inject(AuthService);
  #CoreClientUtilService = inject(CoreClientUtilService);
  #userService = inject(UserService);
  #organizationsService = inject(OrganizationsService);
  #i18nPlatformService = inject(I18nPlatformService);

  greetings:Signal<string>;

  constructor() {
    this.userProfile = this.#userService.profile;
    this.organizations = this.#organizationsService.entries as Signal<UserOrganization[]>;
    this.greetings = this.#i18nPlatformService.tSignal(`menus.user.greetings.${this.#getPeriodOfDay()}`);
  }

  onLogin(): void {
    this.login.emit();
  }

  onLogout(): void {
    this.logout.emit();
  }

  onOrgClicked(organization: UserOrganization): void {
    this.#organizationsService.setCurrent(organization.id, true);
  }

  openUserProfile(): void {
    this.#userService.openProfile();
  }

  addOrganization(): void {
    this.#authService.redirectToCreateOrganization(document.location.href);
  }

  getRoleList(organization: UserOrganization): string {
    return this.#CoreClientUtilService.getRoleList(organization);
  }

  #getPeriodOfDay():PeriodOfDay {
    const nowH = new Date().getHours();
    const periodKey = Object.keys(PERIODS_OF_DAY).reverse().find(key => nowH >= parseInt(key, 10)) as keyof typeof PERIODS_OF_DAY || '17';

    return PERIODS_OF_DAY[periodKey];
  }
}
