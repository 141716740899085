import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard, AuthLogoutGuard } from '@evc/platform';
import type { ImportAsyncComponent } from '@shared/types/utils.type';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

const importPageNotFoundComponent:ImportAsyncComponent = () => import(
  '@shared/components/page-not-found/page-not-found.component'
).then(m => m.PageNotFoundComponent);

export const appRoutes: Route[] = [
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    loadComponent: importPageNotFoundComponent,
    data: { logout: true },
  },
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    loadComponent: importPageNotFoundComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import(
      './pages/users/users.component'
    ).then(m => m.PageUsersComponent),
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'app-licenses',
    loadComponent: () => import(
      './pages/app-licenses/app-licenses.component'
    ).then(m => m.PageAppLicensesComponent),
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'linked-organizations',
    loadComponent: () => import(
      './pages/linked-orgs/linked-orgs.component'
    ).then(m => m.PageLinkedOrgsComponent),
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'organization-settings',
    loadComponent: () => import(
      './pages/org-settings/org-settings.component'
    ).then(m => m.PageOrgSettingsComponent),
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: '**',
    loadComponent: importPageNotFoundComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
];
