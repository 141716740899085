import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'evc-badge-button',
  templateUrl: 'badge-button.component.html',
  styleUrls: ['./badge-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeButtonComponent {
  @Input() public currentText = '';
  @Input() public badgeText = '';
  @Input() public role = '';
}
