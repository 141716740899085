<evc-modal maxWidth="600px" width="100%" [showReduceButton]="false" (closeModal)="closeModal()">
  <span modal-title>{{ 'menus.help.about' | platformTranslate }}</span>

  <div modal-content class="about">
    <evc-svg-icon svgPath="{{ basePath() }}apps/evident-logo.svg#evident" class="about__logo" />
    <div class="about__text">
      <p class="about__app">
        <evc-svg-icon [svgPath]="appIdentity?.logo" size="md" class="about__icon" />
        <span>{{ appIdentity?.title | optionalAsync }}</span>
      </p>
      <p>{{ appIdentity?.version | optionalAsync }}</p>

      <evc-copyright [copyRightText]="getCopyrightText()"></evc-copyright>
    </div>
  </div>
</evc-modal>
