@if (showLoader() || isLoading()) {
  <div
    class="loader"
    data-testid="loader"
    [attr.data-loaded]="loaded()"
  >
  @if (showSpinner() || isLoading()) {
    <evc-loading-spinner class="spinner"/>
  }
  </div>
}