import { MOCK_API_ORG_PAYLOAD } from '../../core-client/organizations/organizations.mock';
import type { ApiProductsResponse, UserApi, UserConfig, UserGreenfieldApi, UserIMSApi, UserProfile } from '../../core-client/user/user.type';
import type { ApplicationType } from '../../types/user-menu.type';

export const MOCK_APPLICATIONS:ApplicationType[] = [
  {
    code: 'drive',
    hasWebApplication: true,
    launchUrl: 'https://drive.olympus-ims-dev.com/drive',
    launchIconUrl: 'https://drive.olympus-ims-dev.com/drive',
    name: 'Drive',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://drive.olympus-ims-dev.com/drive',
  },
  {
    code: 'device-manager',
    hasWebApplication: true,
    launchUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    launchIconUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    name: 'Device Manager',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
  },
];

const MOCKED_USER_BASE = {
  id: 'MOCK_USER_ID',
  displayName: 'Display Name',
  givenName: 'firstname',
  surname: 'lastname',
  email: 'firstname.lastname@evidentscientific.com',
  phone: '+10123456789',
  address: undefined,
} as const;

export const MOCK_USER_CONFIG: UserConfig = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  roles: [{
    id: '0',
    name: 'Admin',
    isReadOnly: false,
  }],
  color: '#badcff', // bg color > compute text light/dark
  // avatar?: string; // URI stay within user
  organizations: MOCK_API_ORG_PAYLOAD,
  applications: MOCK_APPLICATIONS,
};

export const MOCKED_USER_GREENFIELD_DATA: UserGreenfieldApi = {
  ...MOCKED_USER_BASE,
  address: { country: 'mock country', street: 'mock street', city: 'mock city', province: 'NA', postalCode: 'H0H 0H0' },
};

export const MOCKED_USER_IMS_DATA: UserIMSApi = {
  ...MOCKED_USER_BASE,
  role: 'Admin',
  tenantId: 'MOCK_TENANT_ID',
};

export const MOCKED_USER_API_DATA: UserApi = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  roles: [{
    id: '0',
    name: 'Admin',
    isReadOnly: false,
  }],
};

export const MOCK_USER_PROFILE: UserProfile = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  emailVerified: false,
  roles: [{
    id: '0',
    name: 'Admin',
    isReadOnly: false,
  }],
  avatar: {
    type: 'initials',
    color: '#badcff',
    light: true,
    initials: 'FL',
  },
};

export const MOCK_USER_APPLICATIONS: ApiProductsResponse [] = [
  {
    id: '1',
    name: 'MOCK_APP_NAME',
    description: 'MOCK_APP_DESCRIPTION',
    categoryIds: ['MOCK_CATEGORY_ID'],
    icon: {
      id: 'icon_1',
      fileName: 'MOCK_ICON_NAME',
      createdAt: '2022-01-01',
      contentType: 'image/svg+xml',
      url: 'MOCK_ICON_URL',
      type: 'icon',
    },
    document: [],
    availabilityRegions: ['MOCK_REGION'],
    requireLicense: true,
    expirationDate: '2022-01-01',
    licenseType: 'MOCK_LICENSE_TYPE',
    licensingModel: 'MOCK_LICENSING_MODEL',
  },
  {
    id: '2',
    name: 'MOCK_APP_NAME_2',
    description: 'MOCK_APP_DESCRIPTION_2',
    categoryIds: ['MOCK_CATEGORY_ID_2'],
    icon: {
      id: 'icon_2',
      fileName: 'MOCK_ICON_NAME_2',
      createdAt: '2022-01-01',
      contentType: 'image/svg+xml',
      url: 'MOCK_ICON_URL_2',
      type: 'icon',
    },
    document: [],
    availabilityRegions: ['MOCK_REGION_2'],
    requireLicense: true,
    expirationDate: '2022-01-01',
    licenseType: 'MOCK_LICENSE_TYPE_2',
    licensingModel: 'MOCK_LICENSING_MODEL_2',
  },
];

export const MOCKED_USER_LIST_BASE: UserProfile[] = [
  MOCK_USER_PROFILE,
  {
    id: 'MOCK_USER_ID_2',
    displayName: 'Bob Ross',
    givenName: 'Bob',
    surname: 'Ross',
    email: 'bob.ross@gmail.com',
    phone: '+10123456789',
    address: undefined,
    preferredLanguage: 'En',
    emailVerified: false,
    roles: [{
      id: '1',
      name: 'Guest',
      isReadOnly: false,
    }],
  } as UserProfile,
  {
    id: 'MOCK_USER_ID_3',
    displayName: 'Celine Don',
    givenName: 'Celine',
    surname: 'Don',
    email: 'celine@gmail.com',
    phone: '+10123456789',
    address: undefined,
    preferredLanguage: 'En',
    emailVerified: false,
    roles: [{
      id: '2',
      name: 'Owner',
      isReadOnly: false,
    }],
  } as UserProfile,
];
