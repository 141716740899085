<div class="menu">
  @if (showTitle && menuConfig?.title) {
    <div class="menu__title">
      {{ menuConfig?.title }}
    </div>
  }

  @if (!showOnlyBadgeItems) {
    @for (item of menuConfig?.items; track trackByKey) {
      @if (showDividers) {
        <div class="menu__divider"></div>
      }
  
      @if (!item.collapsible) {
        <evc-menu-item [item]="item" (itemClicked)="closeMenu()"></evc-menu-item>
      } @else {
        <evc-collapsible-panel [content]="item">
          @for (collapsibleItem of item.collapsible; track trackByKey) {
            <evc-menu-item
              [item]="collapsibleItem"
              [selected]="isItemSelected(collapsibleItem)"
              (click)="handleCollapsibleItemClick(item, collapsibleItem)"
            ></evc-menu-item>
          }
        </evc-collapsible-panel>
      }
    }
  }

  @if (!showOnlyMenuItems) {
    @for (badgeItem of menuConfig?.badgeItems; track trackByKey) {
      <evc-menu-item [badgeItem]="badgeItem" [selected]="badgeItem.current" (organizationClicked)="handleOrganizationClick(badgeItem)"></evc-menu-item>
    }
  }

  
</div>
