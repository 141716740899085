import { JsonPipe } from '@angular/common';
import type { Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { OrganizationsService } from 'platform/core-client/organizations/organizations.service';
import { UserService } from 'platform/core-client/user/user.service';
import type { UserProfile } from 'platform/core-client/user/user.type';

import type { BadgeItemType } from '@evc/web-components';
import {
  CloseOnClickOutsideDirective,
  CollapsiblePanelComponent,
  MenuItemComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

@Component({
  selector: 'evc-user-menu',
  standalone: true,
  imports: [
    CollapsiblePanelComponent,
    CloseOnClickOutsideDirective,
    MenuItemComponent,
    SvgIconComponent,
    NavButtonComponent,
    JsonPipe,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly login: EventEmitter<void> = new EventEmitter<void>();

  @Output() public readonly logoutClick: EventEmitter<void> = new EventEmitter<void>();

  public userProfile: Signal<UserProfile|undefined>;
  public organizations: Signal<BadgeItemType[]>;

  constructor(
    private readonly _userService: UserService,
    private readonly _organizationsService: OrganizationsService,
  ) {
    this.userProfile = this._userService.profile;
    this.organizations = _organizationsService.entries as Signal<BadgeItemType[]>;
  }

  public handleLogin(): void {
    this.login.emit();
  }

  public handleLogout(): void {
    this.logout.emit();
  }

  public handleOrgClicked(organization: BadgeItemType): void {
    this._organizationsService.setCurrent(organization.id, true);
  }
  public getTimeOfDay(): string {
    return new Date().getHours() < 12 ? 'Good morning' : 'Good afternoon';
  }

  public openUserProfile(): void {
    this._userService.openProfile();
  }
}
