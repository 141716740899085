<div class="evc-platform">
  <div class="evc-platform__page-content-hider"></div>
  <evc-topbar [topbarConfig]="config"
    (login)="handleLogin()"
    (logout)="handleLogout()"
  ></evc-topbar>

  <div class="evc-platform__page-content">
    @if(config.leftBar) {
      <evc-leftbar
        class="evc-platform__leftbar"
        [leftbarConfig]="config.leftBar"
        (isOpenChange)="handleToggleLeftBar($event)"
      >
        <ng-content select="[leftbar-top-content]" top-cta></ng-content>
      </evc-leftbar>
    }

    <div class="evc-platform__app-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>