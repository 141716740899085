/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, firstValueFrom, map, Observable, of, switchMap } from 'rxjs';

import type { Avatar } from '@evc/web-components';

import type {
  ApiOrganizationCreatePayload,
  ApiOrganizationFetchResponse,
  ApiOrganizationUsersFetchResponse,
  ApiPaginatedOrganizationResponse,
  ApiPaginatedOrgUsersResponse,
  ApiSendInviteUserPayload,
  Organization,
} from '../../core-client/organizations/organizations.type';
import { PlatformConfigService } from '../../services/config/config.service';
import type { HttpError } from '../../types/api.type';
import { STORAGE_KEYS } from '../auth/auth.providers';
import { AuthService } from '../auth/auth.service';
import type { UserProfile } from '../user/user.type';
import { CoreClientUtilsService } from '../utils/core-client-utils.service';

const DEFAULT_ORG_COLOR = 'var(--badge-border)';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  #destroyRef = inject(DestroyRef);
  #authService = inject(AuthService);
  #httpClient = inject(HttpClient);
  #configService = inject(PlatformConfigService);
  #coreClientUtilsService = inject(CoreClientUtilsService);

  #currentOrgId:WritableSignal<string|undefined> = signal(undefined);
  get currentOrgId():Signal<string|undefined> {
    return this.#currentOrgId.asReadonly();
  }

  #entries:WritableSignal<Organization[]> = signal([]);
  entries = computed<Organization[]>(() => {
    const current = this.#currentOrgId();

    return this.#entries().map((entry) => ({
      ...entry,
      current: entry.id === current,
    }));
  });

  // ready after fetching orgs
  #ready = signal(false);

  get ready():Signal<boolean> {
    return this.#ready.asReadonly();
  }

  get current():Signal<Organization|undefined> {
    return computed(() => {
      if (!this.#currentOrgId()) return undefined;

      return this.entries().filter((entry) => entry.id === this.#currentOrgId())?.[0];
    });
  }

  /**
   * simply set current org ID > trigger computed so list always with {current:boolean}
   * @param followUpLogin - true only if need to retrigger auth login (new token) (eg on org click - if greenfield)
   */
  setCurrent(id:string | undefined, followUpLogin=false):void {
    this.#currentOrgId.set(id);
    if (this.#configService.greenfield && id && followUpLogin) {
      localStorage.setItem(STORAGE_KEYS.toid, id);
      const loginHint = this.#authService.loginHint();
      if (loginHint) {
        localStorage.setItem(STORAGE_KEYS.loginHint, loginHint);
      }

      this.#authService.logout({
        // TODO create a folow-redirect route to auto login after logout
        postLogoutRedirectUri: window.location.origin,
      });
    }
  }

  setEntries(organizations:Organization[] | ApiOrganizationCreatePayload[] | ApiOrganizationFetchResponse[]):Organization[] {
    const computedOrgs: Organization[] = organizations.map((organization) => this.#computeOrganization(organization));
    this.#entries.set(computedOrgs);
    const currentId = computedOrgs.find((org) => org.current)?.id;
    if (currentId) {
      this.setCurrent(currentId);
    } else {
      const newMatchId = this.#currentOrgId() && computedOrgs.find((org) => org.id === this.#currentOrgId())?.id;
      const newCurrentId = newMatchId || computedOrgs[0]?.id;
      this.setCurrent(newCurrentId);
    }

    return computedOrgs;
  }

  addEntry(organization:Organization | ApiOrganizationCreatePayload): Organization {
    const computedOrg: Organization = this.#computeOrganization(organization);
    this.#entries.update((organizations) => [...organizations, computedOrg]);
    this.setCurrent(computedOrg.id);

    return computedOrg;
  }

  /** fetch organization list and set current one
   * * expected to call this when aquire token (cf platform/app.component) */
  async init(toid?:string|undefined):Promise<void> {
    this.setCurrent(toid);
    await this.updateOrganizations();
    this.#ready.set(true);
  }

  async updateOrganizations():Promise<Organization[]> {
    return firstValueFrom(this.#fetchOrganizations$())
    .then(organizations => this.setEntries(organizations));
  }

  /** check if an organization already exists with this name.
   * resolve only if success
   */
  async checkAlreadyExist(name:string):Promise<boolean> {
    return firstValueFrom(this.#checkAlreadyExist$(name));
  }

  /** create a new org (call api)
   * then add it to our entries
   * ! may only be used by organization app where it will redirect on creation success
   */
  async createOrganization(payload:ApiOrganizationCreatePayload): Promise<void> {
    return firstValueFrom(this.#createOrganization$(payload));
  }

  /** return the list of users for the current organization
   * resolve only if success
   */
  async getUserList(): Promise<UserProfile[]> {
    return firstValueFrom(this.#fetchUserList$())
      .then((users) => this.#computeOrgUsers(users));
  }

  /** send invitation to a user
   * expected to be called from the admin app, by an admin
   * - use token to identify our user as an admin (the sender)
   * - verify that the user is not already in the organization
   * - also provide invitation token so api can fetch which organization etc
   *
   * !!! FAKE CALL - to be implemented !!!
   */
  sendUserInvitation$({ email, role } : ApiSendInviteUserPayload): Observable<boolean> {
    return this.#sendUserInvitation$(email, role);
  }

  /** register a guest user into some organization
   * expected to be called after invitation flow
   * - use token to identify our guest user
   * - also provide invitation token so api can fetch which organization etc
   *
   * !!! FAKE CALL - to be implemented !!!
   */
  confirmUserInvitation$(invitationToken:string): Observable<boolean> {
    return this.#confirmUserInvitation$(invitationToken);
  }

  /** post api returns different format that the get one
   * here we ensure we have the same format
   */
  #computeOrganization(data: Partial<Organization | ApiOrganizationCreatePayload | ApiOrganizationFetchResponse>): Organization {
    const id = (data as Organization).id || (data as ApiOrganizationFetchResponse).organizationId;
    const name = (data as Organization | ApiOrganizationCreatePayload).name || (data as ApiOrganizationFetchResponse).organizationName || '';
    const logo = (data as ApiOrganizationFetchResponse).logo;
    let roles = (data as Organization | ApiOrganizationFetchResponse).roles;

    if (!name) throw new Error('Organization name is required');

    if (!id) throw new Error('Organization id is required');

    if (!roles) roles = [];

    return {
      id,
      name,
      current: (data as Organization).current ?? false,
      avatar: (data as Organization).avatar ?? this.#generateOrgAvatar(name, logo),
      roles: this.#coreClientUtilsService.computeUserRoles(roles),
    };
  }

  #generateOrgAvatar(name: string, logo?: string): Avatar {
    if (logo) {
      return {
        type: 'image',
        src: logo,
      };
    } else {
      return {
        type: 'initials',
        color: DEFAULT_ORG_COLOR,
        light: true,
        initials: this.#computeOrgInitials(name),
      };
    }
  }

  #computeOrgInitials(name: string): string {
    return name.split(' ')
      .map((word) => word[0].toUpperCase())
      .join('')
      .slice(0, 2);
  }

  #computeOrgUsers(usersResponse: ApiOrganizationUsersFetchResponse[]): UserProfile[] {
   return usersResponse.map((userResponse: ApiOrganizationUsersFetchResponse) =>
      this.#coreClientUtilsService.computeUserProfile({
        ...userResponse.user,
        roles: userResponse.roles!,
      }),
    );
  }

  // * ---- API CALLS IMPLEMENTATIONS ---- * //

  #fetchUserList$(): Observable<ApiOrganizationUsersFetchResponse[]> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.listUsers}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiPaginatedOrgUsersResponse>(apiUri)),
      map(({ result }) => result),
      // do not care about pagination here
      map(({ items }) => items),
      catchError(() => of([] as ApiOrganizationUsersFetchResponse[])),
    );
  }

  #fetchOrganizations$(): Observable<ApiOrganizationFetchResponse[]> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.list}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiPaginatedOrganizationResponse>(apiUri, { params: { GetAll: true } })),
      map(({ result }) => result),
      // do not care about pagination here
      map(({ items }) => items),
      catchError(() => of([] as ApiOrganizationFetchResponse[])),
    );
  }

  #checkAlreadyExist$(name:string): Observable<boolean> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.exists}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<boolean>(apiUri, { params: { Name: name } })),
      // result true if not exist - else fail 422...
      catchError((errorHttp:HttpError) => {
        const errorItem = errorHttp.error.errors[0];

        throw errorItem as Error;
      }),
    );
  }

  #createOrganization$(payload:ApiOrganizationCreatePayload):Observable<void> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.create}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.post<void>(apiUri, payload)),
      catchError((errorHttp:HttpError) => {
        throw errorHttp.error.errors as unknown as Error;
      }),
    );
  }

    /**
   * !!! FAKE CALL - to be implemented !!!
   * - if you confirm - simulate success
   * - if you cancel - simulate error
   */
  #sendUserInvitation$(emails:string, role:string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const message = `SIMULATE API CALL [sent invite user in org]
        emails : ${emails},
        role : ${role}
      `;

      if (window.confirm(message)) {
        observer.next(true);
        observer.complete();
      } else {
        observer.error(new Error('api-error'));
      }
    });
  }

  /**
   * !!! FAKE CALL - to be implemented !!!
   * - if you confirm - simulate success
   * - if you cancel - simulate error
   */
  #confirmUserInvitation$(invitationToken:string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const message = `SIMULATE API CALL [confirm user invited in org]

      invitation token : ${invitationToken}`;

      if (window.confirm(message)) {
        observer.next(true);
        observer.complete();
      } else {
        observer.error(new Error('api-error'));
      }
    });
  }
}
