import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, type Observable } from 'rxjs';

import { deepMerge } from '@evc/web-components';

import { TranslateHttpLoader } from './i18n.service';

export class I18NLoader extends TranslateHttpLoader {
  #httpClient = inject(HttpClient);
  #destroyRef = inject(DestroyRef);

  override getTranslation(lang: string): Observable<Record<string, unknown>> {
    return combineLatest([
      this.#httpClient.get<JSON>(`/assets/i18n/${lang}.json`),
      this.#httpClient.get<JSON>(`/assets/i18n/shared-${lang}.json`),
    ])
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      map(([app, shared]: [JSON, JSON]) => deepMerge(shared, app)),
    );
  }
}
