import type { ApiOrganizationFetchResponse, Organization, Role, UserOrganization } from './organizations.type';

export const MOCK_USER_ORGANIZATIONS: UserOrganization[] = [{
  id: '1',
  name: 'UserOrganization 1',
  current: false,
  avatar: {
    type: 'initials',
    color: 'red',
    light: true,
    initials: 'BA',
  },
  roles: [],
}, {
  id: '2',
  name: 'An other UserOrganization',
  current: true,
  avatar: {
    type: 'image',
    src: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Test-Logo.svg',
  },
  roles: [],
}];

export const MOCK_API_ORG_PAYLOAD: ApiOrganizationFetchResponse[] = [
  {
    organizationId: '1',
    organizationName: 'UserOrganization 1',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Test-Logo.svg',
  },
  {
    organizationId: '2',
    organizationName: 'An other UserOrganization',
  },
];

export const MOCK_ORGANIZATIONS: Organization[] = [
  {
    id: 'MOCK_ORG_ID',
    name: 'Mock Org',
    phone: '1234567890',
    addresses: [],
    eTag: '1234567890',
    roles: [
      {
        id: '1',
        name: 'Admin',
        isReadOnly: true,
      },
    ],
  },
];

export const MOCKED_ROLE_OPTIONS: Role[] = [
  {
    id: '1',
    name: 'Admin',
    isReadOnly: true,
  },
  {
    id: '2',
    name: 'User',
    isReadOnly: false,
  },
];
