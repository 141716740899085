import { ChangeDetectionStrategy, Component, computed, Input, Signal } from '@angular/core';

import type { UserProfile } from '@evc/platform';
import { SvgIconComponent } from '@evc/web-components';
import { TranslatePipe } from '@shared/services/i18n/i18n.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'adm-user-details',
  standalone: true,
  imports: [TranslatePipe, SvgIconComponent],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent {
  @Input() user!: Signal<UserProfile>;

  formatedAddress = computed(() => `
    ${ this.user().address?.street },
    ${ this.user().address?.city } (${ this.user().address?.province }),
    ${ this.user().address?.postalCode },
    ${ this.user().address?.country }
  `);
}
