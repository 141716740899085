/** App config
 * - define idp endpoints, tenants name etc for each environment
 * - then compute MSAL config from it
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { getAuthProviders } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     ...getAuthProviders(environment),
 *   ],
 * };
 * ```
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import type {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import type { IPublicClientApplication } from '@azure/msal-browser';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import type { PlatformEnv } from 'platform/providers/env.type';
import type { AuthConfig } from 'platform/services/config/config.type';

/** UTILITIES : we cannot get from shared here */
type ValueOf<T> = T[keyof T];

type MapFunction<T, U> = (value: T, key: string, index: number) => U;
function objectMap<T extends Record<string, unknown>, U>(obj: T, fn: MapFunction<T[keyof T], U>): Record<string, U> {
  return Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v as T[keyof T], k, i)],
    ),
  );
}

/* way to inject config from a callback to pass .env config : see getAuthProviders()*/
export const AUTH_CONFIG = new InjectionToken<string>('auth config');

/** compute some extra config depending on env config */
export function getAuthConfig(environment:PlatformEnv):AuthConfig {
  const auth = environment.auth!;
  const { clientId, tenant, policies } = auth;
  type Policy = ValueOf<typeof policies>;

  const AUTHORITY_DOMAIN = `${tenant}.b2clogin.com`;
  const AUTHORITY_URI = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/`/* + policyName */;

  return {
    ...auth,
    scopes: [clientId],
    b2cPolicies: {
      names: policies,
      authorities: objectMap(policies, (policy: Policy) => `${AUTHORITY_URI}${policy}`),
      authorityDomain: AUTHORITY_DOMAIN,
    },
  };
}

/** generate needed providers for MSAL - to be imported in app.config.ts */
export function getAuthProviders(environment:PlatformEnv) { // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
  if (!environment.auth || !environment.api) {
    return [
      {
        provide: AUTH_CONFIG,
        useFactory: () => ({}),
      },
    ];
  }

  const config = getAuthConfig(environment);
  const ENV = environment.ENV;
  const API_URI = environment.api.uri;

  function MSALInstanceFactory(): IPublicClientApplication {
    const { clientId, redirects, b2cPolicies } = config;

    const logLevel = ENV === 'production'
      ? LogLevel.Error
      : (ENV === 'staging' ? LogLevel.Warning : LogLevel.Verbose);

    return new PublicClientApplication({
      auth: {
        authority: b2cPolicies.authorities.signUpSignIn as string,
        knownAuthorities: [b2cPolicies.authorityDomain],
        clientId,
        redirectUri: redirects.success,
        postLogoutRedirectUri: redirects.logout,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          logLevel,
          piiLoggingEnabled: false,
          // // KEEP IN NEED - you may implement your logger logic here
          // loggerCallback:(_logLevel: LogLevel, message: string)=>{
          //   if (env === 'production') return;
          //   console.log('[msal]', message); // eslint-disable-line no-console
          // }
        },
      },
    });
  }

  function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const { scopes } = config;
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(API_URI, scopes);

    return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
    };
  }

  function MSALGuardConfigFactory(): MsalGuardConfiguration {
    const { scopes, b2cPolicies, redirects } = config;

    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        authority: b2cPolicies.authorities.signUpSignIn as string,
        scopes: [...scopes],
      },
      loginFailedRoute: redirects.fail,
    };
  }

  return [
    {
      provide: AUTH_CONFIG,
      useFactory: () => config,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor, // you can set your own @see exemples/_auth.intercepror.ts,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}
export default getAuthProviders;
