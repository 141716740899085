import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconType } from '../../types/icon-list.type';

@Component({
  selector: 'evc-svg-icon',
  standalone: true,
  imports: [],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() public icon?: IconType;
  @Input() public svgPath?: string;
  @Input() public size: 'logo' | 'lg' | 'md' | 'sm' | 'xs' | 'free' = 'free';
  @Input() public basePath = '/';
}
