import { Injectable } from '@angular/core';

import type { Menu } from '@evc/web-components';
import { BtnActionEnum, ModalService, ThemeService } from '@evc/web-components';

import type { AppLinks } from '../../types/app-links.type';

@Injectable({
  providedIn: 'root',
})
export class DefaultConfigService {
  private _appsLinks : AppLinks = {
    marketplace: 'https://app.olympus-ims.com/marketplace/store',
    user: 'https://user.evidentscientific.com',
    admin: 'https://admin.evidentscientific.com',
  };

  private _basePath = '/';

  private _DEFAULT_HELP_MENU: Menu = {
    title: 'Information',
    items: [
      // {
      //   text: 'Help & Support',
      //   key: 'help-and-support',
      //   action: {
      //     type: BtnActionEnum.URL,
      //     callbackOrUrl: 'https://evident-products.atlassian.net/wiki/spaces/WSCLOUD/overview',
      //   },
      // },
      // {
      //   text: 'Release Notes',
      //   key: 'release-notes',
      //   action: {
      //     type: BtnActionEnum.URL,
      //     callbackOrUrl: 'https://evident-products.atlassian.net/wiki/spaces/WSCLOUD/overview',
      //   },
      // },
      // {
      //   text: 'Terms & Conditions',
      //   key: 'terms-and-conditions',
      //   action: {
      //     type: BtnActionEnum.URL,
      //     callbackOrUrl: 'https://evident-products.atlassian.net/wiki/spaces/WSCLOUD/overview',
      //   },
      // },
      {
        text: 'About',
        key: 'about',
        action: {
          type: BtnActionEnum.CALLBACK,
          callbackOrUrl: (): void => this._modalService.showCurrentModal('about'),
        },
      },
    ],
  };

  private _DEFAULT_SETTING_MENU: Menu = {
    title: 'Quick Setting',
    items: [
      {
        text: 'Theme',
        key: 'theme',
        collapsible: [
          {
            text: 'Light',
            key: 'light',
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => {
                this._themeService.changeTheme('light');
              },
            },
            img: '/assets/theme/light.webp',
          },
          {
            text: 'Dark',
            key: 'dark',
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => this._themeService.changeTheme('dark'),
            },
            img: '/assets/theme/dark.webp',
          },
          {
            text: 'Auto',
            key: 'auto',
            selected: true,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => this._themeService.changeTheme('auto'),
            },
            img: '/assets/theme/auto.webp',
          },
        ],
      },
      // {
      //   text: 'Advanced app settings',
      //   key: 'advanced-app-settings',
      //   action: {
      //     type: BtnActionEnum.URL,
      //     callbackOrUrl: 'https://evident-products.atlassian.net/wiki/spaces/WSCLOUD/overview',
      //   },
      // },
    ],
  };

  private _DEFAULT_APP_TITLE = 'Evident Connect';
  private _DEFAULT_APP_LOGO = '/assets/apps/withe_icon_unis 1.svg#withe_icon_unis 1';

  public get DEFAULT_APP_TITLE(): string {
    return this._DEFAULT_APP_TITLE;
  }

  public get DEFAULT_HELP_MENU(): Menu {
    return this._DEFAULT_HELP_MENU;
  }

  public get DEFAULT_SETTING_MENU(): Menu {
    return this._DEFAULT_SETTING_MENU;
  }

  public get DEFAULT_APP_LOGO(): string {
    return this._DEFAULT_APP_LOGO;
  }

  public get appsLinks(): AppLinks {
    return this._appsLinks;
  }

  public set appsLinks(appsLink: AppLinks) {
    this._appsLinks = appsLink;
  }

  public get basePath(): string {
    return this._basePath;
  }

  public set basePath(basePath: string) {
    this._basePath = basePath;
  }

  constructor(
    private readonly _themeService: ThemeService,
    private readonly _modalService: ModalService,
  ) {}
}
